import React, {useState, useCallback, useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Advertisement, CarData} from "./VinReport";
import {formatDate} from "../table/Table";
import Logo from "../Logo";


const AdvertisementDetail: React.FC<{ advertisement: Advertisement }> = ({ advertisement }) => {


    function GetPortalLogo(portal: string) {
        switch (portal) {
            case "AUTO.BAZOS.CZ":
                return "/img/bazos.png"
            case "SAUTO":
                return "/img/sauto.webp"
            case "RAJAUT":
                return "/img/rajaut.png"
            case "SPORTOVNIVOZY":
                return "/img/sportovnivozy.png"
            case "RAJVETERANU":
                return "/img/rajveteranu.png"
            default:
                return "https://auto-ari.cz/assets/sauto-logo.webp"
        }
    }


    return (
        <Row className={"report-body"}>
            <Col>
                <Row>
                    <Col md={2}>
                        <p>{formatDate(advertisement.created_at)}</p>
                        <p><a target={"_blank"} rel="noreferrer" href={advertisement.url}><img width={100}
                                                                                               src={GetPortalLogo(advertisement.source)}
                                                                                               alt={advertisement.source}></img></a>
                        </p>
                        {advertisement.location && <p>{advertisement.location}</p>}
                        <p>{advertisement.price}.-</p>
                        <p><a target={"_blank"} href={advertisement.url}>Odkaz</a></p>
                    </Col>
                    {advertisement.images ?
                        <Col md={2}>
                            <img style={{"maxHeight": "80%", "maxWidth": "100%"}} src={advertisement.images[0]}
                                 alt={advertisement.source}/>
                        </Col>
                        :
                        <Logo></Logo>
                    }
                    <Col md={8}>
                        <p className={"text-left"}>{advertisement.description}</p>
                    </Col>
                </Row>
            </Col>
            <hr/>
        </Row>
    );
}

export default AdvertisementDetail;
