import React from "react";
import {Button, Card, Carousel, Col, Row} from "react-bootstrap";
import {Ad} from "../filters/FilterDetail";
import {UserSubscription} from "../App";
import BasicInfoSwiper from "./BasicInfoSwiper";
import './SwiperDetail.css';
import {Link} from 'react-router-dom';
import {FaThumbsDown, FaThumbsUp} from 'react-icons/fa';

const SwiperDetail: React.FC<{
    advertisement: Ad;
    dislikeHandle: () => void;
    likeHandle: () => void;
    userSubscription: UserSubscription;
}> = ({ advertisement, dislikeHandle, likeHandle, userSubscription }) => {

    function checkVin(string: string): boolean {
        // Check if the string length is 17
        if (string.length !== 17) {
            return false; // String length is not 17
        }

        // Extract the last 5 characters from the string
        const lastFiveDigits = string.substr(-5);

        // Check if the last 5 characters are "xxxxx"
        if (lastFiveDigits === "XXXXX") {
            return false; // Last 5 digits are "xxxxx" and length is 17
        } else {
            return true; // Last 5 digits are not "xxxxx"
        }
    }

    return (
        <>
            {advertisement &&
                <Row>
                    <Col>
                        <Card className="swiper-card">
                            <Card.Body>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Col>
                                                {advertisement.media &&
                                                    <Carousel>
                                                        {advertisement.media.map(medium => (
                                                            <Carousel.Item key={medium}>
                                                                <Card.Img variant="top" className="swiper-image" src={medium} />
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                }
                                            </Col>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row>
                                                <Col xs={12}>
                                                    <BasicInfoSwiper ad={advertisement}></BasicInfoSwiper>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Col xs={12}>
                                                <Row className={"mt-3"}>
                                                    <Col className="text-left">
                                                        <Button className="swipe-button dislike-button" onClick={dislikeHandle} variant="outline-danger">
                                                            <FaThumbsDown />
                                                        </Button>
                                                    </Col>
                                                    <Col className="text-center pt-2">
                                                        <a target="_blank" className={"btn btn-outline-secondary btn-sm report-button btn-custom"} rel="noopener noreferrer" href={advertisement.url}>
                                                            Inzerát
                                                        </a>
                                                    </Col>
                                                    {(advertisement.vin_complete || (advertisement.vin && checkVin(advertisement.vin))) &&
                                                        <Col className="text-center pt-2">
                                                            <Link target="_blank" to={{
                                                                pathname: `/report/${advertisement.vin}`,
                                                                // @ts-ignore
                                                                state: { userSubscription: userSubscription },
                                                            }} className="btn btn-sm btn-outline-success">
                                                                Report
                                                            </Link>
                                                        </Col>
                                                    }
                                                    <Col className="text-right">
                                                        <Button className="swipe-button like-button" onClick={likeHandle} variant="outline-success">
                                                            <FaThumbsUp />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                    </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
}

export default SwiperDetail;
