import React, { useState, useEffect } from "react";
import { Ad } from "../filters/FilterDetail";
import { API_HOST } from "../App";
import axios, { AxiosError } from 'axios';
import SwiperDetail from "./SwiperDetail";
import { NotificationManager } from "react-notifications";
import FullScreenLoading from "../loading/FullScreenLoading";
import EmptyGarage from "../loading/EmptyGarage";
import OutOfQuota from "./OutOfQuota";


interface SwiperProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}

const Swiper: React.FC<SwiperProps> = (props) => {
    const [advertisements, setAdvertisements] = useState<Ad[]>([]);
    const [currentAd, setCurrentAd] = useState<Ad | null>(null);
    const [currentAdIx, setCurrentAdIx] = useState<number>(0);
    const [swipeCounter, setSwipeCounter] = useState<number>(0);
    const [availableAt, setAvailableAt] = useState<Date>(new Date());
    const [preloadAd, setPreloadAd] = useState<Ad | null>(null);
    const [showAd, setShowAd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [outOfQuota, setOutOfQuota] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState<number>(0);

    useEffect(() => {
        // Parse availableAt to a Date object if it's a string
        const availableDate =
            typeof availableAt === "string" ? new Date(availableAt) : availableAt;

        // Check if availableAt is a valid Date object
        if (!(availableDate instanceof Date) || isNaN(availableDate.getTime())) {
            console.error("Invalid availableAt date provided:", availableAt);
            return;
        }

        const interval = setInterval(() => {
            const now = new Date();
            const timeDiff = (availableDate.getTime() - now.getTime()) / 1000;
            setTimeRemaining(Math.floor(Math.max(timeDiff, 0)));
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [availableAt]);



    const fetchUserAds = async () => {
        setLoading(true); // Set loading to true at the beginning of the fetch operation
        let host = API_HOST;
        let filterRoute = "/swipe";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };

        try {
            const response = await axios.get(host + filterRoute, config);
            if (response.data) {
                let ads: Ad[] = response.data;
                setAdvertisements(ads);
                if (ads.length > 0) {
                    setCurrentAdIx(0);
                    setCurrentAd(ads[0]);
                    if (ads.length > 1) {
                        setPreloadAd(ads[1]);
                    } else {
                        setPreloadAd(null);
                    }
                    setOutOfQuota(false);
                }
            } else {
                setAdvertisements([]);
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && error.response.status === 429) {
                    setOutOfQuota(true);
                    setAdvertisements([])
                    setCurrentAd(null)
                    setAvailableAt(error.response.data.available_at)
                } else {
                    NotificationManager.error("Failed to fetch advertisements");
                }
            } else {
                NotificationManager.error("An unexpected error occurred");
            }
        } finally {
            setLoading(false); // Set loading to false after the fetch operation is complete
            setShowAd(true); // Ensure that the ad is shown when the component mounts
        }
    };


    function prepend(value: Ad, array: Ad[]) {
        var newArray = array.slice();
        newArray.unshift(value);
        return newArray;
    }

    async function retrain() {
        let host = API_HOST;
        let filterRoute = "/swipe/retrain";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        const response = await axios.post(host + filterRoute, {}, config);

    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (currentAd) {
                if (event.key === 'ArrowLeft') {
                    adDisliked();
                } else if (event.key === 'ArrowRight') {
                    adLiked();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [currentAd]);
    const fetchUserAdsLive = async () => {
        let host = API_HOST;
        let filterRoute = "/swipe";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
        try {
            
            const response = await axios.get(host + filterRoute, config);
            let newAds: Ad[] = response.data;
    
            // If the current ad is still in the new list, update advertisements and preserve the current ad position
            const currentAdIndex = newAds.findIndex(ad => currentAd && ad.id === currentAd.id);
    
            if (currentAdIndex !== -1) {
                // The current ad is in the new list
                setAdvertisements(prevAds => {
                    // Remove ads before the current one in the new list
                    const updatedAds = newAds.slice(currentAdIndex);
                    // Merge the remaining new ads with the old ones, avoiding duplicates
                    const mergedAds = [...prevAds.slice(0, currentAdIndex + 1), ...updatedAds.filter(ad => !prevAds.map(a => a.id).includes(ad.id))];
                    return mergedAds;
                });
            } else {
                setAdvertisements(prevAds => [...newAds, ...prevAds]);
            }
    
            // Ensure preloadAd is correctly set based on the current ad index
            const nextAdIndex = currentAdIndex + 1;
            if (nextAdIndex < newAds.length) {
                setPreloadAd(newAds[nextAdIndex]);
            } else {
                setPreloadAd(null); // No more ads to preload
            }

        } catch (error) {
            if (error instanceof AxiosError) {
                // Now that we're sure it's an AxiosError, we can access AxiosError specific properties like response
                if (error.response && error.response.status === 429) {
                    setOutOfQuota(true);
                    setAdvertisements([])
                    setCurrentAd(null)
                    setAvailableAt(error.response.data.available_at)
                } else {
                    NotificationManager.error("Failed to fetch advertisements");
                }
            } else {
                NotificationManager.error("An unexpected error occurred");
            }
        } finally {
            setLoading(false); // Set loading to false after the fetch operation is complete
            setShowAd(true); // Ensure that the ad is shown when the component mounts
        }
        
    };

    useEffect(() => {
        fetchUserAds();
        setShowAd(true); // Ensure that the ad is shown when the component mounts
    }, [props.userSubscription]);

    const sendRating = (ad: Ad | null, value: number) => {

        if (ad === null) {
            NotificationManager.error("Ad is null");
            return
        }

        var payload = {
            "advertisement": ad.id,
            "value": value,
        }

        let host = API_HOST;
        let filterRoute = "/swipe";

        const config = {
            headers:{
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };

        axios.post(host + filterRoute, JSON.stringify(payload), config)
            .then(function (response) {
                let sc = swipeCounter + 1
                setSwipeCounter(sc)
                if (sc > 20) {
                    fetchUserAdsLive()
                    setSwipeCounter(0)
                }
            })
            .catch(function (error) {
                NotificationManager.error(error);
            });
    }

    const moveForward = () => {
        // Trigger exit animation
        setShowAd(false);

        // After a short delay to allow the exit animation to play, move to the next ad
        setTimeout(() => {
            const nextIx = currentAdIx + 1;
            setCurrentAdIx(nextIx);
            setCurrentAd(advertisements[nextIx]);

            // Preload the next advertisement
            const nextPreloadIx = nextIx + 1;
            if (nextPreloadIx < advertisements.length) {
                setPreloadAd(advertisements[nextPreloadIx]);
            } else {
                setPreloadAd(null); // No more ads to preload
            }

            // Trigger enter animation
            setShowAd(true);
        }, 250); // Match this delay to the duration of your exit animation
    };


    const adLiked = () => {
        sendRating(currentAd, 1)
        moveForward()
    };

    const adDisliked = () => {
        sendRating(currentAd, 0)
        moveForward()
    };

    return (
        <>
            {loading &&
                <FullScreenLoading></FullScreenLoading>
            }
            {currentAd && (
                <div className={showAd ? 'fade-enter fade-enter-active' : 'fade-exit fade-exit-active'}>
                    <SwiperDetail
                        key={currentAd.id}
                        userSubscription={props.userSubscription}
                        advertisement={currentAd}
                        dislikeHandle={() => adDisliked()}
                        likeHandle={() => adLiked()}
                    />
                </div>
            )}
            {preloadAd && (
                <div style={{display: 'none'}}>
                    <SwiperDetail
                        userSubscription={props.userSubscription}
                        key={preloadAd.id}
                        advertisement={preloadAd}
                        dislikeHandle={() => {
                        }}
                        likeHandle={() => {
                        }}
                    />
                </div>
            )}
            {!loading && (advertisements.length === 0 || !currentAd) && !outOfQuota &&
                <EmptyGarage></EmptyGarage>
            }
            {outOfQuota &&
                <>
                    <OutOfQuota timeRemaining={timeRemaining} setUserSubscription={props.setUserSubscription} userSubscription={props.userSubscription}></OutOfQuota>
                    <div className="text-center">{timeRemaining <= 0 && <button onClick={fetchUserAds} className={"btn btn-primary"}>Načíst</button>}</div>
                </>
            }
        </>
    );
};

export default Swiper;
