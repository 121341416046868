import UserSubscription, {API_HOST, BMW_PRICE, FERRARI_PRICE, SKODA_PRICE} from "../App";
import React, {Dispatch, SetStateAction, useState} from "react";
import Col from "react-bootstrap/Col";
import QRModal from "../dashboard/QRModal";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import { Link } from "react-router-dom";
import MainNavbar from "../navbar/Navbar";
import Container from "react-bootstrap/Container";

const SettingsConditions: React.FC = () => {

    return (
        <>
              <MainNavbar userSubscription={null}></MainNavbar>
              <Container className={"container-report"}>
                     <h1 className={"pt-5 pb-5"}>Obchodní podmínky</h1>
                     <p>
                     Identifikace subjektu Služba carnotifier.eu je provozována společností  DSMS Software s.r.o. Se sídlem: Nové sady 988/2, Staré Brno, 602 00 Brno, IČO: 21322180, zapsané v obchodním rejstříku pod sp. zn. C 138226 vedené u Krajského soudu v Brně.
                     </p>
                     <p>
                     Tyto podmínky používání carnotifier.eu vymezují práva a povinnosti mezi společností,  DSMS Software s.r.o. Se sídlem: Nové sady 988/2, Staré Brno, 602 00 Brno, IČO: 21322180, zapsané v obchodním rejstříku pod sp. zn. C 138226 vedené u Krajského soudu v Brně,
                     , provozovatelem služby carnotifier.eu, a vámi, uživatelem služby carnotifier.eu, která je dostupná na https://carnotifier.eu (dále jen „Portál“).
                     </p>
                     <p>
                     Uživatelem je každá osoba, která v souladu s těmito podmínkami provedla registraci na carnotifier.eu, případně využila bezplatnou nebo placenou verzi služby carnotifier.eu. Vzhledem k platné právní úpravě se rozlišuje mezi uživatelem, který je spotřebitelem, tedy tím, který při uzavírání a plnění smlouvy nejedná v rámci své obchodní nebo jiné podnikatelské činnosti, a uživatelem, který není spotřebitelem, tedy tím, který si objednává službu za účelem svého podnikání.
                     </p>
                     <p>
                     Používáním carnotifier.eu ať již jste registrovaní, nebo ne – dáváte najevo, že souhlasíte se zásadami ochrany osobních údajů a s obchodními podmínkami, resp. podmínkami platnými v okamžiku vstupu do carnotifier.eu, a zavazujete se je při užívání carnotifier.eu dodržovat. Vyhrazujeme si právo na změnu těchto podmínek. Jste-li registrovaným uživatelem, o změně podmínek vás budeme informovat prostřednictvím e-mailu, který jste zadali při registraci, případně prostřednictvím emailu spojeným s účtem služby discord při registraci pomocí služby discord. Nové znění podmínek bude účinné od okamžiku oznámení dle předchozí věty tohoto odstavce. V případě, že s novým zněním podmínek nebudete souhlasit, svou registraci můžete kdykoliv zrušit. Pokud budete i po provedených změnách jakkoliv užívat carnotifier.eu, má se za to, že jste se změnami podmínek seznámil a bezvýhradně s nimi souhlasíte.
                     </p>

                     <p>
                     Registrace, předplatné a užívání carnotifier.eu
                     Obsah na carnotifier.eu je přístupný pouze uživatelům, kteří se registrovali na carnotifier.eu. Bonusový obsah na carnotifier.eu je přístupný pouze uživatelům,   kteří mají aktivní předplatné, Registrace na carnotifier.eu proběhne prostřednictvím registračního formuláře dostupného na Portále.
                     Jste povinni nám poskytovat pravdivé a úplné údaje požadované při registraci. Při uvedení nepravdivých a/nebo neúplných údajů nesete veškerou odpovědnost za újmu tímto způsobenou. V případě chybného uvedení údajů jste povinni chybu odstranit samostatně v sekci Nastavení a není-li to možné, pak prostřednictvím e-mailu support@carnotifier.eu;
                     Jsme oprávněni obrátit se na vás za účelem ověření aktuálnosti, resp. správnosti poskytnutých osobních údajů. Údaje, u nichž bude existovat opodstatněná pochybnost o jejich správnosti, budou blokovány s tím, že pokud je nebude možné opravit nebo ověřit, popř. by takováto oprava nebo ověření bylo spojeno s neúměrnými náklady nebo obtížemi, dojde k jejich likvidaci;
                     Pokud budete chtít svoji registraci zrušit, může tak učinit kdykoliv ve svém uživatelském účtu v Nastavení.
                     Jste povinni chránit si svá přístupová hesla. V případě ztráty či jeho zneužití jste povinni nás ihned informovat tak, abychom mohli přijmout přiměřená opatření. Upozorňujeme vás, že nejsme odpovědni za škody vzniklé v důsledku ztráty nebo zneužití vašich přístupových údajů.
                     Aktuální cena placené verze carnotifier.eu je uvedena v českých korunách na portále. S placenou verzí služby carnotifier.eu nejsou spojeny další náklady na dodání. K platbě předplatného na carnotifier.eu dochází prostřednictvím QR platby a po připsání peněz na účet společnosti se aktivuje předplatné na měsíc v případě měsíčního tarifu, jednou ročně v případě ročního tarifu.
                     </p>
                     <p>
                     Předplatné přestává být aktivní ihned po uplynutí časové periody dle zvoleného tarifu, případně po zrušení předplatného. Poměrná cena za zbývající dny do konce měsíčního nebo ročního období se nevrací. Svůj účet můžete kdykoliv kompletně smazat, v tom případě nás kontaktujte na e-mail support@carnotifier.eu; Mějte prosím na paměti, že po smazání vašeho účtu není možné vaše data nijak obnovit. Děkujeme za pochopení.
                     Elektronická objednávka na placenou verzi carnotifier.eu je dostupná na Portále. Pro vyplnění objednávky si musíte vytvořit uživatelský účet a poté zakoupit předplatné prostřednictvím qr platby. Koupí předplatného potvrzujete, že jste se s těmito podmínkami seznámili a že s nimi bez výhrad souhlasíte.
                     Úhradou předplatného zasíláte svůj návrh na uzavření smlouvy. K uzavření smlouvy dochází okamžikem potvrzení přijetí objednávky, které vám zašleme e-mailem na e-mailovou adresu, kterou jste uvedli při vyplnění objednávky. Služba carnotifier.eu bude aktivní od okamžiku potvrzení objednávky.
                     Úhradou předplatného nám udělujete výslovný souhlas s poskytováním služby ihned po uzavření smlouvy. Díky tomu získáte k bonusovému obsahu carnotifier.eu okamžitý přístup po potvrzení objednávky. Jelikož Vám bude služba poskytnuta ještě před uplynutím čtrnáctidenní lhůty pro odstoupení od smlouvy, která je zákonem pro spotřebitele stanovena, pozbýváte v souladu s ustanovením § 1837 písm. a) občanského zákoníku právo od smlouvy odstoupit, a to ve chvíli, kdy už jste službu prokazatelně začali čerpat. Čerpání služby je například swipování, vytváření filtrů apod.
                     Odesláním objednávky souhlasíte s použitím komunikačních prostředků na dálku při uzavírání smlouvy. Náklady, které vám vzniknou při použití komunikačních prostředků na dálku v souvislosti s uzavřením smlouvy (náklady na internetové připojení, náklady na telefonní hovory), si hradíte sami.
                     </p>
                     <p>
                     Vaše osobní údaje
                     Zpracování vašich osobních údajů provádíme v souladu s Nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. 4. 2016, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen „GDPR“) a zákonem č. 110/2019 Sb., o zpracování osobních údajů, v platném znění.
                     Vaše osobní údaje jsou u nás v bezpečí – nepředáváme je žádným třetím stranám. Víc informací najdete v našich zásadách pro ochranu osobních údajů.
                     </p>
                     <p>
                     Ke svému uživatelskému účtu na carnotifier.eu můžete přistupovat z více zařízení, sdílení účtu s více osobami je ale zakázáno. V případě porušení tohoto pravidla může bez náhrady dojít ke smazání vašeho účtu.
                     </p>
                     <p>
                     Zavazujete se, že nebudete zveřejňovat na discord serveru carnotifier žádné příspěvky, které jsou výhružné, pomlouvačné nebo nezákonné povahy, anebo které jakýmkoli způsobem porušují naše práva nebo práva třetích osob. carnotifier.eu si vyhrazuje právo na to smazat jakýkoliv post bez udání důvodů. Současně si vyhrazujeme právo vám okamžitě ukončit nebo pozastavit na dobu neurčitou poskytování služeb carnotifier.eu, pokud porušíte tyto podmínky, anebo se dopouštíte nepřípustného chování, přičemž toto posouzení podléhá čistě našemu uvážení a může být provedeno i bez udání důvodu.
                     </p>
                     <p>
                     Berete na vědomí, že programové vybavení a další součásti tvořící carnotifier.eu jsou chráněny autorským právem. Zavazujete se, že nebude vykonávat žádnou činnost, která by vám nebo třetím osobám mohla umožnit neoprávněně zasahovat či neoprávněně užít programové vybavení nebo další součásti tvořící carnotifier.eu. Nejste oprávněni při využívání carnotifier.eu používat mechanismy, programové vybavení nebo jiné postupy, které by mohly mít negativní vliv na provoz carnotifier.eu; carnotifier.eu je možné užívat jen v rozsahu, který není na úkor práv ostatních uživatelů a který je v souladu s jeho určením.
                     </p>
                     <p>
                     Neodpovídáme ani neručíme za přesnost a validitu informací, které se objevují na carnotifier.eu. Informace jsou sbírány za využití data miningu, datových analýz a pocházejí z různých zdrojů, přičemž nelze ověřit a zaručit jejich přesnost.
                     </p>
                     <p>
                     Neodpovídáme ani neručíme za závazky osob, jejichž nabídky se mohou objevit na carnotifier.eu; Nejsme účastníkem žádných právních vztahů, které navážete s takovými třetími osobami. Kliknutím na některé odkazy na carnotifier.eu může dojít k opuštění carnotifier.eu a k přesměrování na webové stránky třetích subjektů. Neneseme odpovědnost za obsah stránek třetích osob, na které je odkazováno z carnotifier.eu, ani za případné porušení právních předpisů třetími osobami provozujícími tyto stránky a případnou škodu či jinou újmu tím způsobenou. Zařazení jakéhokoliv odkazu na carnotifier.eu neznamená náš souhlas s takovou stránkou či její podporu, propagaci apod., pokud tak není výslovně uvedeno.
                     </p>
                     <p>
                     Vadné plnění a odstoupení od smlouvy
                     Práva a povinnosti smluvních stran ohledně práv z vadného plnění se řídí příslušnými obecně závaznými předpisy. Jste povinni zkontrolovat funkčnost objednané služby carnotifier.eu bez zbytečného odkladu po jejím dodání. Vadu jste povinni nám oznámit bez zbytečného odkladu po jejím zjištění na e-mailovou adresu support@carnotifier.eu; V případě, že je vada odstranitelná, můžete požadovat opravu, doplnění toho, co chybí, nebo přiměřenou slevu z ceny. V případě, že vadu nelze odstranit a z tohoto důvodu nelze službu carnotifier.eu řádně užívat, můžete odstoupit od smlouvy nebo požadovat přiměřenou slevu z ceny.
                     </p>
                     <p>
                     Kontakt a další ujednání
                     Máte otázku? Napište nám na support@carnotifier.eu;
                     Tyto podmínky jsou vyhotoveny pouze v českém jazyce. Smlouva může být uzavřena pouze v českém jazyce. Uzavřenou smlouvu budeme archivovat, z technických důvodů však nebude k dispozici.
                     </p>
                     <p>
                     V případě, že jakékoliv ustanovení těchto podmínek je neplatné anebo nevykonatelné, neovlivní to platnost ostatních ustanovení. Ta zůstanou platná a vykonatelná podle podmínek v nich stanovených.
                     </p>
                     <p>
                     Veškeré smluvní vztahy vzniklé mezi námi a vámi při uzavření smlouvy dle těchto podmínek se řídí právním řádem České republiky. Veškeré spory vzniklé ze smlouvy uzavřené dle těchto podmínek a právních vztahů touto smlouvou založených budou řešeny českým soudem, jehož místní příslušnost bude určena podle adresy našeho sídla.
                     Vyřizování vašich stížností zajišťujeme prostřednictvím e-mailové adresy support@carnotifier.eu
                     Nejsme vázáni žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) občanského zákoníku.
                     </p>
                     <p>
                     K poskytování služby carnotifier.eu jsme oprávněni na základě živnostenského oprávnění. Živnostenskou kontrolu provádí v rámci své působnosti příslušný živnostenský úřad. Dozor nad oblastí ochrany osobních údajů vykonává Úřad pro ochranu osobních údajů. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.
                     Uživatel, který je spotřebitelem, má právo na mimosoudní řešení spotřebitelského sporu. Subjektem mimosoudního řešení spotřebitelských sporů je Česká obchodní inspekce (www.coi.cz), se sídlem Štěpánská 567/15, 120 00 Praha 2.
                     Ustanovení směřující k ochraně spotřebitele dle právních předpisů a těchto podmínek se nevztahují na uživatele, kteří do smluvního vztahu s námi vstoupí jako podnikatelé.
                     Tyto podmínky jsou platné od 3. května 2024.


                     </p>
              </Container>
        </>
    )
}

export default SettingsConditions;