import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from "../App";
import axios from 'axios';
import {NotificationManager} from "react-notifications";
import LoadingModal from "../reports/LoadingModal";
import FullScreenLoading from "../loading/FullScreenLoading";

interface LoginProps {
    setLoggedIn: (loggedIn: boolean) => void;
}

const DiscordRedirect: React.FC<LoginProps> = (props: LoginProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.substring(1)); // Remove '#' and parse
        const accessToken = params.get('access_token');

        if (accessToken) {
            let loginRoute = "/login";

            axios.post(API_HOST + loginRoute, JSON.stringify({ "token": accessToken }))
                .then(function (response: any) {
                    const token = response.data.token;
                    const discord = response.data.discord;
                    localStorage.clear();
                    localStorage.setItem("token", token);
                    props.setLoggedIn(true)
                    navigate('/');
                })
                .catch(function (error) {
                    NotificationManager.error("Invalid credentials");
                    console.error('Error:', error);
                });
        } else {
            // Handle case where no access token is present in the URL
            navigate('/'); // Redirect back to login page or show an error
        }
    }, [navigate]);

    return (
        <div>
            <FullScreenLoading></FullScreenLoading>
        </div>
    );
};

export default DiscordRedirect;
