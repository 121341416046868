import React from "react";
import { useTable } from "react-table";

export function formatDate(date) {
    if (!date) {
        return null
    }
    const d = new Date(date);
    return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear()
}

export default function Table({ tableKey, columns, data, selected, onSelectedChanged = (id) => { } }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
            columns,
            data,
        },
    );

    const onRowClick = (e, id) => {
        let row = e.target.closest("tr");
        row.classList.toggle('bg-success')
        row.classList.toggle('text-white')
        onSelectedChanged(id)
    }

    const onRowShiftDrag = (e, id) => {
        if (e.shiftKey) {
            let row = e.target.closest("tr");
            row.classList.toggle('bg-success')
            row.classList.toggle('text-white')
            onSelectedChanged(id)
        }
    }

    return (
        <div className={"table-responsive"}>
            <table className={"table w-100 table-striped table-bordered"} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, _) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}><div className="truncate">{cell.render("Cell")}</div></td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div >
    );
}
