import React, {useState, useCallback, useEffect} from "react";
import Logo from "../Logo";
import Container from "react-bootstrap/Container";
import { useNavigate } from 'react-router-dom';

interface LoginProps {
    setLoggedIn: (loggedIn: boolean) => void;
}

const LogoutForm:  React.FC<LoginProps> = (props: LoginProps) => {

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        props.setLoggedIn(false);
        navigate('/');
    }, []);

    return (
      <>
          <Container>

          </Container>
      </>
    );
}

export default LogoutForm;
