import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import AdvertisementDetail from '../filters/AdvertisementDetail';
import Ad from '../filters/FilterDetail';
import FullScreenLoading from '../loading/FullScreenLoading';
import { API_HOST } from '../App';
import UserSubscription from '../App';

interface CarListing {
    id: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    vin: string;
    url: string;
    source: string;
    vin_complete: boolean;
    description: string;
    media: any[] | null;
    mileage_km: number;
    price_currency: string;
    price_value: number;
    seller_name: string;
    seller_email: string;
    seller_phone: string;
    seller_city: string;
    seller_district: string;
    seller_country: string;
    drivetrain: string;
    transmission: string;
    manufacturer: string;
    engine_energy: string;
    engine_volume: number;
    engine_power_kw: number;
    body_style: string;
    manufactured_year: number;
    model: string;
    color: string;
    raw_equipment: string;
}

interface ManufacturerModels {
    manufacturer: string;
    models: string[];
}

interface FormFields {
    manufacturerModels: ManufacturerModels[];
    mileage: number;
    price: number;
    fuelType: string[];
    transmission: string[];
    drivetrain: string[];
    bodyStyles: string[];
}

interface ManufacturerModelsDict {
    [manufacturer: string]: string[];
}

interface QBProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: React.Dispatch<React.SetStateAction<UserSubscription | null>>;
}

type NotificationType = 'EMAIL' | 'DISCORD_DM';

const AdvertsQueryBuilderDisplay: React.FC<QBProps> = (props) => {
    const [filterName, setFilterName] = useState<string | null>(null);
    const [selectedManufacturer, setSelectedManufacturer] = useState<string | null>(null);
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const [transmission, setTransmission] = useState<string | null>(null);
    const [fuelType, setFuelType] = useState<string | null>(null);
    const [formFields, setFormFields] = useState<FormFields | null>(null);
    const [priceUpperLimit, setPriceUpperLimit] = useState<number | null>(null);
    const [priceLowerLimit, setPriceLowerLimit] = useState<number | null>(null);
    const [mileageUpperLimit, setMileageUpperLimit] = useState<number | null>(null);
    const [mileageLowerLimit, setMileageLowerLimit] = useState<number | null>(null);
    const [powerLowerLimit, setPowerLowerLimit] = useState<number | null>(null);
    const [powerUpperLimit, setPowerUpperLimit] = useState<number | null>(null);
    const [manufacturedDateUpperLimit, setManufacturedDateUpperLimit] = useState<number | null>(null);
    const [manufacturedDateLowerLimit, setManufacturedDateLowerLimit] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // @ts-ignore
    const [filterCars, setFilterCars] = useState<Ad[] | null>(null);
    const [selectedNotificationType, setSelectedNotificationType] = useState<NotificationType>('EMAIL');

    useEffect(() => {
        async function SetFields() {
            let host = API_HOST;
            let filterRoute = '/filter';
            const config = {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            };
            const response = await axios.get(host + filterRoute, config);

            let fields: FormFields = {
                manufacturerModels: [],
                mileage: 0,
                price: 0,
                fuelType: [],
                transmission: [],
                drivetrain: [],
                bodyStyles: [],
            };

            let manufacturerModels: ManufacturerModelsDict = {};

            response.data.forEach((item: { manufacturer: string; model: string }) => {
                if (!manufacturerModels[item.manufacturer]) {
                    manufacturerModels[item.manufacturer] = [];
                }
                manufacturerModels[item.manufacturer].push(item.model);
            });

            Object.keys(manufacturerModels).forEach((manufacturer) => {
                fields.manufacturerModels.push({
                    manufacturer: manufacturer,
                    models: manufacturerModels[manufacturer],
                } as ManufacturerModels);
            });

            fields.fuelType = ['CNG', 'DIESEL', 'ELECTRIC', 'HYBRIDE', 'HYDROGEN', 'LPG', 'PETROL'];
            fields.transmission = ['AT', 'MT'];
            fields.drivetrain = ['AWD', 'FWD', 'RWD'];
            fields.bodyStyles = []; // Fill this if needed

            setIsLoading(false);
            setFormFields(fields);
        }

        SetFields();
    }, []);

    const handleFormChange = (event: React.ChangeEvent<any>) => {
        const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;

        switch (name) {
            case 'Jméno filtru':
                setFilterName(value ? value : null);
                break;
            case 'Rok od':
                setManufacturedDateLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Rok do':
                setManufacturedDateUpperLimit(value ? parseInt(value) : null);
                break;
            case 'Cena od':
                setPriceLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Cena do':
                setPriceUpperLimit(value ? parseInt(value) : null);
                break;
            case 'Km od':
                setMileageLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Km do':
                setMileageUpperLimit(value ? parseInt(value) : null);
                break;
            case 'Výkon od':
                setPowerLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Výkon do':
                setPowerUpperLimit(value ? parseInt(value) : null);
                break;
            case 'fuelType':
                setFuelType(value);
                break;
            case 'transmission':
                setTransmission(value);
                break;
            default:
                break;
        }
    };

    const handleManufacturerChange = (event: React.ChangeEvent<any>) => {
        setSelectedManufacturer(event.target.value);
        setSelectedModel(null); // Reset model selection
    };

    const handleModelChange = (event: React.ChangeEvent<any>) => {
        setSelectedModel(event.target.value);
    };


    const handleSubmit = async (event: React.FormEvent<any>) => {
        event.preventDefault();

        const filterQuery = buildPayload();

        if (Object.keys(filterQuery).length < 2) {
            NotificationManager.warning('Vyberte aspoň 2 parametry');
            return;
        }

        const payload = {
            filter_name: filterName,
            filter: JSON.stringify(buildPayload()),
            hook_type: selectedNotificationType,
        };

        let host = API_HOST;
        let filterRoute = '/filter';

        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };

        try {
            await axios.post(host + filterRoute, JSON.stringify(payload), config);
            NotificationManager.info('Filter pridan');
            window.location.href = '/filter';
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (!error.response || error.response.status === 421) {
                    NotificationManager.error('Limit filtru dosažen, odoberte filtry, nebo zakupte vyšší předplatné.');
                } else {
                    NotificationManager.error(error.response?.data?.message || 'An error occurred');
                }
            } else if (error instanceof Error) {
                NotificationManager.error(error.message);
            } else {
                NotificationManager.error('An error occurred');
            }
        }
    };

    const buildPayload = () => {
        const query: any = {};

        if (selectedManufacturer) {
            query.manufacturer = selectedManufacturer;
        }

        if (selectedModel) {
            query.model = selectedModel;
        }

        if (transmission) {
            query.transmission = transmission;
        }

        if (fuelType) {
            query.engine_energy = fuelType;
        }

        if (priceLowerLimit !== null || priceUpperLimit !== null) {
            query.price_value = {};
            if (priceLowerLimit !== null) {
                query.price_value.$gte = priceLowerLimit;
            }
            if (priceUpperLimit !== null) {
                query.price_value.$lte = priceUpperLimit;
            }
        }

        if (mileageLowerLimit !== null || mileageUpperLimit !== null) {
            query.mileage_km = {};
            if (mileageLowerLimit !== null) {
                query.mileage_km.$gte = mileageLowerLimit;
            }
            if (mileageUpperLimit !== null) {
                query.mileage_km.$lte = mileageUpperLimit;
            }
        }

        if (powerLowerLimit !== null || powerUpperLimit !== null) {
            query.engine_power_kw = {};
            if (powerLowerLimit !== null) {
                query.engine_power_kw.$gte = powerLowerLimit;
            }
            if (powerUpperLimit !== null) {
                query.engine_power_kw.$lte = powerUpperLimit;
            }
        }

        if (manufacturedDateLowerLimit !== null || manufacturedDateUpperLimit !== null) {
            query.manufactured_year = {};
            if (manufacturedDateLowerLimit !== null) {
                query.manufactured_year.$gte = manufacturedDateLowerLimit;
            }
            if (manufacturedDateUpperLimit !== null) {
                query.manufactured_year.$lte = manufacturedDateUpperLimit;
            }
        }

        return query;
    };

    const handleDisplayCars = async (event: React.FormEvent<any>) => {
        event.preventDefault();

        const filterQuery = buildPayload();

        if (Object.keys(filterQuery).length < 2) {
            NotificationManager.warning('Vyberte aspoň 2 parametry');
            return;
        }

        setIsLoading(true);

        const payload = {
            filter: JSON.stringify(buildPayload()),
        };

        let host = API_HOST;
        let filterRoute = '/filter/cars';

        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        };

        try {
            const response = await axios.post(host + filterRoute, JSON.stringify(payload), config);
            setFilterCars(response.data);
        } catch (error) {
            NotificationManager.error('An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {(!isLoading && formFields) ? (
                <Row className={"bg-light qb-background pt-2 pb-2"}>
                    <Form onSubmit={handleSubmit}>
                        <h2 className={'pb-2 pt-2'}>Vyhledávač</h2>
                        <hr/>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group controlId="manufacturer">
                                            <Form.Label>Výrobce</Form.Label>
                                            <Form.Control as="select" value={selectedManufacturer ?? ''}
                                                          onChange={handleManufacturerChange}>
                                                <option value="">Výrobce</option>
                                                {formFields.manufacturerModels.map(({manufacturer}) => (
                                                    <option key={manufacturer} value={manufacturer}>
                                                        {manufacturer}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr/>
                                {selectedManufacturer && (
                                    <>
                                        <Row className={"mb-1"}>
                                            <Col md={12}>
                                                <Form.Group controlId="model">
                                                    <Form.Label>Model</Form.Label>
                                                    <Form.Control as="select" value={selectedModel ?? ''}
                                                                  onChange={handleModelChange}>
                                                        <option value="">Model</option>
                                                        {formFields.manufacturerModels
                                                            .find(({manufacturer}) => manufacturer === selectedManufacturer)
                                                            ?.models.map(model => (
                                                                <option key={model} value={model}>
                                                                    {model}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </>
                                )}
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="yearFrom">
                                                    <Form.Label>Rok od</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Rok od"
                                                        value={manufacturedDateLowerLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="yearTo">
                                                    <Form.Label>Rok do</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Rok do"
                                                        value={manufacturedDateUpperLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="priceFrom">
                                                    <Form.Label>Cena od</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Cena od"
                                                        value={priceLowerLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="priceTo">
                                                    <Form.Label>Cena do</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Cena do"
                                                        value={priceUpperLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={6}>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="mileageFrom">
                                                    <Form.Label>Km od</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Km od"
                                                        value={mileageLowerLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="mileageTo">
                                                    <Form.Label>Km do</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Km do"
                                                        value={mileageUpperLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="powerForm">
                                                    <Form.Label>Výkon od (kW)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Výkon od"
                                                        value={powerLowerLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="powerTo">
                                                    <Form.Label>Výkon do (kW)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Výkon do"
                                                        value={powerUpperLimit ?? ''}
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Palivo</Form.Label>
                                            <Form.Control as="select" name="fuelType" value={fuelType ?? ''} onChange={handleFormChange}>
                                                <option value="">Palivo</option>
                                                {formFields.fuelType.map(fuel => (
                                                    <option key={fuel} value={fuel}>
                                                        {fuel}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Převodovka</Form.Label>
                                            <Form.Control as="select" name="transmission" value={transmission ?? ''} onChange={handleFormChange}>
                                                <option value="">Převodovka</option>
                                                {formFields.transmission.map(transmission => (
                                                    <option key={transmission} value={transmission}>
                                                        {transmission}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr/>
                        <Col xs={12} className={"btn-right text-center pt-5 pb-5 mb-5"}>
                            <Button variant="info" onClick={handleDisplayCars} type="submit">Otestuj filter</Button>
                        </Col>
                    </Form>
                    {filterCars && filterCars.map((ad, index) => (
                        <>
                            <AdvertisementDetail advertisement={ad}></AdvertisementDetail>
                            <Row className={"mt-2 mb-2"}></Row>
                        </>
                    ))}
                    <Col md={8} className={"p-6"}>
                        {filterCars ? (
                            <Row className={"bg-light pt-4"}>
                                <Col>

                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            ) : <FullScreenLoading/>}
        </>
    );
};

export default AdvertsQueryBuilderDisplay;
