import UserSubscription, {API_HOST, BMW_PRICE, FERRARI_PRICE, SKODA_PRICE} from "../App";
import React, {Dispatch, SetStateAction, useState} from "react";
import Col from "react-bootstrap/Col";
import QRModal from "../dashboard/QRModal";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import MainNavbar from "../navbar/Navbar";
import Container from "react-bootstrap/Container";
import Swiper from "../swiper/Swiper";

interface DashboardProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}

const Subscription: React.FC<DashboardProps> = (props) => {

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [qrURL, setQrUrl] = useState("");

    const closeModal = () => {
        setOpenModal(false);
    };

    const handleQRGenereate = async (amount: number, sub: string) => {
        let host = API_HOST;
        let subscriptionRoute = "/subscription/qr";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };

        try {
            let response = await axios.post(host + subscriptionRoute, {
                subscription: sub
            }, {    ...config,
                responseType: 'blob'});
            const url = URL.createObjectURL(new Blob([response.data]));
            setPrice(amount)
            setSubscription(sub)
            setQrUrl(url)
            setOpenModal(true)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Handle Axios error
                NotificationManager.error("problem s generovanim qr kodu");
            } else if (error instanceof Error) {
                // Handle generic errors
                NotificationManager.error(error.message);
            } else {
                // Handle unknown errors
                NotificationManager.error("An unknown error occurred");
            }
        }
    };

    function formatDate(dateString: string) {
        let date = new Date(dateString);

        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        let year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    function GetLogo(manufacturer: string) {
        return "/img/" + manufacturer.toLowerCase() + "-promo.png"
    }

    return (
        <div className={"bg-light"}>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container className={"bg-light min-vh-100"}>
                <Row className={"bg-light p-4"}>
                    <Col>
                        <QRModal showModal={openModal} qrURL={qrURL} closeModal={closeModal} subscription={subscription}
                                 price={price} userSubscription={props.userSubscription}
                                 setUserSubscription={props.setUserSubscription}></QRModal>
                        <h1>Předplatné</h1>
                        <Row className={"subscriptions bg-light pb-5"}>
                            <Col xs={12} md={4} className={"sub sub-disabled"}>
                                <Row>
                                    <Col xs={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <h2 className={"text-center text-light"}>
                                            <img className={"sub-manufacturer"} src={GetLogo("skoda")} alt="SKODA"/>
                                        </h2>
                                        </Col>
                                    <Col xs={12} md={12} className={"mt-3"}>
                                        <ul>
                                            <li>1x filtr na inzeráty s notifikacemi</li>
                                            <li>5x VIN report</li>
                                            <li>20x swipe na nové inzeráty denne</li>
                                        </ul>
                                    </Col>
                                    <Col xs={12} md={12} className={"text-center text-light"}>
                                        <Button disabled={true} className={"mt-4"} variant="primary">
                                            {SKODA_PRICE},- kč / měsíčně
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <hr className={"display-md-none"}/>
                            <Col xs={12} md={4} className={"sub sub-disabled"}>
                                <Row>
                                    <Col xs={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <h2 className={"text-center text-light"}>
                                            <img className={"sub-manufacturer"} src={GetLogo("bmw")} alt="BMW"/>
                                        </h2>
                                    </Col>
                                    <Col xs={12} md={12} className={"mt-3"}>
                                        <ul>
                                            <li>2x filtr na inzeráty s notifikacemi</li>
                                            <li>10x VIN report</li>
                                            <li>Neomezený swiper na nové inzeráty</li>
                                        </ul>
                                    </Col>
                                    <Col xs={12} md={12} className={"text-center text-light"}>
                                        <Button disabled={true} className={"mt-4"} variant="primary">
                                            {BMW_PRICE},- kč  / měsíčně
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <hr className={"display-md-none"}/>
                            <Col xs={12} md={4} className={"sub sub-promo"}>
                                <Row>
                                    <h4 className={"text-center pb-4 text-center"}>Speciální úvítací nabídka!</h4>
                                    <Col xs={{span: 12, offset: 0}} md={{span: 12, offset: 0}}>
                                        <h2 className={"text-center text-light"}>
                                            <img className={"sub-manufacturer"} src={GetLogo("ferrari")} alt="Ferrari"/>
                                        </h2>
                                    </Col>
                                    <Col xs={12} md={12} className={"mt-3"}>
                                        <ul>
                                            <li>5x filtr na inzeráty s notifikacemi</li>
                                            <li>Neomezený počet VIN reportů</li>
                                            <li>Neomezený swiper na nové inzeráty</li>
                                        </ul>
                                    </Col>
                                    <Col xs={12} md={12} className={"text-center text-light"}>
                                        <Button className={"mt-4"} variant="primary"
                                                onClick={() => handleQRGenereate(FERRARI_PRICE, "FERRARI")}>
                                            <s>599</s> {FERRARI_PRICE},- kč   / měsíčně
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Subscription;