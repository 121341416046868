import React from 'react';

const EmptyGarage = () => {
    return (
        <div className="eg-container mt-5">
            <div className="eg-content">
                <img src="/img/empty.png" alt="garage-empty" className="eg-garage"/>
                <p className={"eg-text"}>Garáž je prázdna, žádná nová auta prozatím nenalezeny.</p>
            </div>
        </div>
)
    ;
};

export default EmptyGarage;
