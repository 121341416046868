import React, {useState, useCallback, useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Ad } from "../filters/FilterDetail";

function GetLogo(manufacturer: string) {
    return "/img/" + manufacturer.toLowerCase() + ".png"
}

function formatDate(dateString: string) {
    let date = new Date(dateString);

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

function isOk(info: any) {
    return !(info === undefined || info === 0 || info === "" || info === "01.01.1" || info === "UNKNOWN")
}

export function formatDateType(date: Date) {
    if (!date) {
        return null
    }
    date = new Date(date);
    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
}

const BasicInfoDetail: React.FC<{ ad: Ad }> = ({ ad }) => {

    let eq = ad

    return (
            <Row className={"report-body"}>
                <Col>
                    <Row className={"swiper-report-header"}>
                        <Col xs={12} md={12} className={"d-none d-md-block"}>
                            <Row>
                                {isOk(eq.manufacturer) &&
                                    <Col xs={6} md={4}><p><strong>Výrobce:</strong> {eq.manufacturer}</p></Col>}
                                {isOk(eq.model) && <Col xs={6} md={4}><p><strong>Model:</strong> {eq.model}</p></Col>}
                                {isOk(eq.price_value) &&
                                    <Col xs={6} md={4}><p><strong>Cena:</strong> {eq.price_value}{eq.price_currency}</p>
                                    </Col>}
                                {isOk(eq.mileage_km) &&
                                    <Col xs={6} md={4}><p><strong>Najeto:</strong> {eq.mileage_km}km</p></Col>}
                                {isOk(eq.manufactured_year) &&
                                    <Col xs={12} md={4}><p><strong>Rok výroby:</strong> {eq.manufactured_year}</p>
                                    </Col>}
                                {isOk(eq.created_at) &&
                                    <Col xs={12} md={4}><p><strong>Datum přidání:</strong> {formatDateType(eq.created_at)}</p>
                                    </Col>}
                                {isOk(eq.body_style) &&
                                    <Col xs={6} md={4}><p><strong>Karosérie:</strong> {eq.body_style}</p></Col>}
                                {isOk(eq.vin) && <Col xs={12} md={4}><p><strong>VIN:</strong> {eq.vin}</p></Col>}
                                {isOk(eq.engine_cylinders) &&
                                    <Col xs={6} md={4}><p><strong>Válců motoru:</strong> {eq.engine_cylinders}</p>
                                    </Col>}
                                {isOk(eq.engine_power_kw) &&
                                    <Col xs={6} md={4}><p><strong>Výkon motoru:</strong> {eq.engine_power_kw}kW</p>
                                    </Col>}
                                {isOk(eq.engine_volume) &&
                                    <Col xs={6} md={4}><p><strong>Objem motoru:</strong> {eq.engine_volume}cm3</p>
                                    </Col>}
                                {isOk(eq.top_speed) &&
                                    <Col xs={6} md={4}><p><strong>Maximální rychlost:</strong> {eq.top_speed}km/h</p>
                                    </Col>}
                                {isOk(eq.transmission) &&
                                    <Col xs={6} md={4}><p><strong>Převodovka:</strong> {eq.transmission}</p></Col>}
                                {isOk(eq.engine_energy) &&
                                    <Col xs={6} md={4}><p><strong>Palivo:</strong> {eq.engine_energy}</p></Col>}
                                {isOk(eq.wheel_drive) &&
                                    <Col xs={6} md={4}><p><strong>Pohon kol:</strong> {eq.wheel_drive}</p></Col>}
                                <p>
                                    {eq.description && eq.description.substring(0, 200)}
                                </p>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} className={"d-md-none pt-4"}>
                            <Row>
                            <p style={{fontWeight: "bold"}}>
                                {eq.manufacturer} {eq.model}
                            </p>
                            <p>
                                {eq.price_value && eq.price_value + ",-" + eq.price_currency + " "}
                                {eq.mileage_km && eq.mileage_km + "km "}
                                {eq.manufactured_year && "r.v." + eq.manufactured_year + " "}
                                {eq.seller_city && eq.seller_city + " "}
                                {eq.created_at && formatDateType(eq.created_at) + " "}
                            </p>
                            <p>
                                {eq.description && eq.description.substring(0, 100)}
                            </p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
    );
}

export default BasicInfoDetail;
