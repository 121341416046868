import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import LogoNavbar from "../LogoNavbar";
import {Link} from "react-router-dom";
import UserSubscription from "../App";
import React from "react";
import {BsFileText, BsFillHouseFill, BsFilter, BsSearch} from 'react-icons/bs'; // Importing icons for the menu


interface NavbarProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
}



const MainNavbar: React.FC<NavbarProps> = (props) => {

    return (
    <>
        <Navbar data-bs-theme="dark" expand="lg" className={"navbar-bg-custom"}>
            <Container>
                <LogoNavbar></LogoNavbar>
            </Container>
        </Navbar>
        <Navbar fixed="bottom" className="navbar-bg-custom">
            <Container className="justify-content-around navbar-size">
                <Nav className="w-100 d-flex justify-content-around align-items-center navbar-bg-custom">
                    {/* Swiper Nav Item */}
                    <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                        <Link to="/" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                            <BsFillHouseFill className={"d-none d-md-block"} />
                            <span className="racing">Swiper</span>
                        </Link>
                    </Nav.Item>

                    {/* Lookup Nav Item */}
                    <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                        <Link to="/search" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                            <BsSearch className={"d-none d-md-block"} />
                            <span className="racing">Hledáček</span>
                        </Link>
                    </Nav.Item>

                    {/* Filters Nav Item */}
                    <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                        <Link to="/filter" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                            <BsFilter className={"d-none d-md-block"} />
                            <span className="racing">Notifikace</span>
                        </Link>
                    </Nav.Item>

                    {/* Report Nav Item */}
                    <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                        <Link to="/report" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                            <BsFileText className={"d-none d-md-block"} />
                            <span className="racing">Report</span>
                        </Link>
                    </Nav.Item>

                    {/* Profile Nav Item */}
                    <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                        <Link to="/settings" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                            {props.userSubscription &&
                                <img height="20px" className={"d-none d-md-block"}
                                     alt={props.userSubscription.subscription.toLowerCase()}
                                     src={`/img/${props.userSubscription.subscription.toLowerCase()}.png`}/>
                            }
                            <span className="racing">Nastavení</span>
                        </Link>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>

    </>
);
}

export default MainNavbar;