import React, {useEffect, useState} from 'react';
import AdvertisementDetail from "../filters/AdvertisementDetail";
import { useParams } from 'react-router-dom';
import MainNavbar from "../navbar/Navbar";
import { Row } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import axios from 'axios';
import { NotificationManager } from "react-notifications";
import FullScreenLoading from "../loading/FullScreenLoading";
import Container from "react-bootstrap/Container";
import {API_HOST} from "../App";

interface FilterProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
}

export interface Media {
    URL: string;
}

export interface Ad {
    id: number;
    created_at: Date;
    date: Date;
    source: string;
    description: string;
    location: string;
    url: string;
    price_value: number;
    price_currency: string;
    manufacturer: string;
    model: string;
    manufactured_year: number;
    engine_power_kw: number;
    mileage_km: number;
    CreatedAt: number;
    seller_city: number;
    media: string[];
    vin: string;
    vin_complete: boolean;
    body_style: string;
    engine_volume: number;
    engine_cylinders: number;
    top_speed: number;
    transmission: string;
    engine_energy: string;
    wheel_drive: string;
}

export interface Filter {
    filter_name: string
}

export interface FilterMatches {
    advertisements: Ad[];
    filter: Filter;
}

const FilterDetail: React.FC<FilterProps> = (props) => {

    const [filterMatch, setFilterMatch] = useState<FilterMatches|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    let { id } = useParams();

    useEffect(() => {
        axios.get(API_HOST + '/filter/detail', {
            params: { filter_id: id },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                setFilterMatch(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                NotificationManager.error("Filter není váš, nebo nastal problém s načítaním.")
            });
    }, [id]);

    if (isLoading) {
        return <div>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <FullScreenLoading></FullScreenLoading>
        </div>;
    }

    return (
        <div className={"min-vh-100"}>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container>
            <Row>
                <h1 className={"pt-5 h3"}>Detail filtru {filterMatch?.filter.filter_name}</h1>
                <a href={"/filter"} className={"pb-5"}>zpět na filtry</a>
                <Col>
                    {filterMatch?.advertisements && filterMatch?.advertisements.map((ad, index) => (
                        <>
                            <AdvertisementDetail advertisement={ad}></AdvertisementDetail>
                            <Row className={"mt-2 mb-2"}></Row>
                        </>
                    ))}
                </Col>
            </Row>
            </Container>
        </div>
    );
}

export default FilterDetail;
