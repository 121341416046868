import React, { useState } from "react";
import { Button, Form, Col, Row } from 'react-bootstrap';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { API_HOST } from "../App";
import { WEB_HOST } from "../App";

const discordClientId = "1214192063080570890";

interface LoginProps {
    setLoggedIn: (loggedIn: boolean) => void;
}

const LoginForm: React.FC<LoginProps> = (props: LoginProps) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const nav = useNavigate();

    const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleDiscordLogin = () => {
        const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${encodeURIComponent(WEB_HOST + "/login/discord")}&response_type=token&scope=email+identify`;
        window.location.href = discordAuthUrl;
    };

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault(); // Prevent form submission

        function isValidEmail(email: string) {
            return /\S+@\S+\.\S+/.test(email);
        }

        if (!isValidEmail(username)) {
            NotificationManager.error("Invalid email");
            return;
        }

        let host = API_HOST;

        if (password === '') {
            let generateRoute = "/login/generate-or-register";
            setShowPassword(true);
            NotificationManager.success("Zkontrolujte svůj email pro jednorázové heslo.");

            axios.post(host + generateRoute, JSON.stringify({ "email": username }))
                .then(function (response) {

                })
                .catch(function (error) {
                    NotificationManager.error("Problem s prihlasenim");
                });
        } else {
            let loginRoute = "/login";

            axios.post(host + loginRoute, JSON.stringify({ "email": username, "password": password }))
                .then(function (response: any) {
                    const token = response.data.token;
                    localStorage.clear();
                    localStorage.setItem("token", token);
                    props.setLoggedIn(true)
                    NotificationManager.success("Login successful");
                })
                .catch(function (error) {
                    NotificationManager.error("Invalid credentials");
                    console.error('Error:', error);
                });
        }
    }

    return (
        <Row xs={12}>
            <Col>
                <Row className={"text-center pb-3"}>
                    <h2 className={"color-secondary"}>Registrace a přihášení</h2>
                </Row>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Group controlId="username">
                                    <Form.Control
                                        type="email"
                                        name="username"
                                        placeholder={"E-mail"}
                                        onChange={handleUsername}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {showPassword &&
                            <Row className={"mt-3"}>
                                <Col>
                                    <Form.Group controlId="password">
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder={"E-mail kód"}
                                            onChange={handlePassword}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        <Row className={"text-center mt-3"}>
                            <Col>
                                <Button className={"button-custom-primary"} type="submit">
                                    {!showPassword ? "Potvrdit email" : "Přihlásit"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row className={"mt-3"}>
                    <Col className={"text-center text-light"}>
                        <p>nebo</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className={"discord-sso text-center"}>
                            <Col>
                                <img width={"50%"} src="/img/discordsign.png" onClick={handleDiscordLogin} alt=""/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default LoginForm;
