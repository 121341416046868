import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {formatDate} from "../table/Table";
import {Ad} from "./FilterDetail"
import LogoNavbar from "../LogoNavbar";


const AdvertisementDetail: React.FC<{ advertisement: Ad }> = ({ advertisement }) => {


    function GetPortalLogo(portal: string) {
        switch (portal) {
            case "AUTO.BAZOS.CZ":
                return "/img/bazos.png"
            case "SAUTO":
                return "/img/sauto.webp"
            case "RAJAUT":
                return "/img/rajaut.png"
            case "SPORTOVNIVOZY":
                return "/img/sportovnivozy.png"
            case "RAJVETERANU":
                return "/img/rajveteranu.png"
            default:
                return "https://auto-ari.cz/assets/sauto-logo.webp"
        }
    }

    return (
        <Row className={"report-body"}>
            <Col>
                <Row>
                    <Col lg={2} xs={6}>
                        <p><a target={"_blank"} rel="noreferrer" href={advertisement.url}><img width={100}
                                                                                               loading="lazy"
                                                                                               src={GetPortalLogo(advertisement.source)}
                                                                                               alt={advertisement.source}></img></a>
                        </p>
                        <p>{advertisement.price_value}.- {advertisement.price_currency}</p>
                        <p>{formatDate(advertisement.created_at)}</p>
                        <p><a target={"_blank"} href={advertisement.url}>Odkaz</a></p>
                        {advertisement.location && <p>{advertisement.location}</p>}
                    </Col>
                    <Col lg={4} xs={6} className={"pt-2"}>
                        {(advertisement.media && advertisement.media.length) ?
                            <img alt={advertisement.model} loading="lazy" width={"100%"} src={advertisement.media[0]}/>
                            :
                            <LogoNavbar></LogoNavbar>
                        }
                    </Col>
                    <Col lg={6} xs={12}>
                        <h5 className={"text-left"}>{advertisement.manufacturer} {advertisement.model} {advertisement.manufactured_year && "rv." + advertisement.manufactured_year}</h5>
                        <p className={"text-left"}>{advertisement.description}</p>
                    </Col>
                    <hr/>
                </Row>
            </Col>
        </Row>
    );
}

export default AdvertisementDetail;
