import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import {Utils as QbUtils} from "@react-awesome-query-builder/ui";
import {NotificationManager} from "react-notifications";
import {API_HOST} from "../App";

// @ts-ignore
export default function QRModalButton({ showModal, closeModal, qrURL, subscription, price, userSubscription, setUserSubscription }) {

    const [form, setForm] = React.useState({
        tickets: [],
    });
    const [code, setCode] = useState<string>('');

    // @ts-ignore
    const handleSubmit = async (event) => {
        event.preventDefault()
        let host = API_HOST;
        let filterRoute = "/subscription";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };

        try {
            const response = await axios.get(host + filterRoute, config);
            const userSub = response.data;
            setUserSubscription(userSub);
            if (userSub.subscription === "") {
                NotificationManager.info("Členství zatím nebylo registováno, zkuste to o minutku.");
            } else {
                NotificationManager.success("Členství úspěšně aktivované");
                closeModal();
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (!error.response || error.response.status === 404) {
                    setUserSubscription(null);
                    NotificationManager.info("Členství zatím nebylo registováno, zkuste to o minutku.");
                } else {
                    // Handle other Axios errors
                }
            } else if (error instanceof Error) {
                // Handle generic errors
                NotificationManager.error(error.message);
            } else {
                // Handle unknown errors
                NotificationManager.error("An unknown error occurred");
            }
        }
    }

    function GetLogo(manufacturer: string) {
        return "/img/" + manufacturer.toLowerCase() + ".png"
    }

    return (
        <>

            <Modal show={showModal}>
                <Form className={"decision-app"} onSubmit={(e) => {
                    handleSubmit(e);
                }}>
                    <Modal.Header closeButton>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Modal.Title>QR Platba</Modal.Title>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-1" controlId="result">
                            <Col xs={12} md={12} className={"sub"}>
                                <Row>
                                    <Col className={"text-center"}>
                                        {qrURL ? <img className={"qr"} src={GetLogo(subscription)} alt="qr code"/> : <p>Loading...</p>}
                                    </Col>
                                    <Col className={"text-center"}>
                                        {qrURL ? <img className={"qr"} src={qrURL} alt="qr code"/> : <p>Loading...</p>}
                                    </Col>
                                </Row>
                                <Row className={"mt-4 mb-4"}>
                                    <Col className={"text-center qr-price"}>
                                        {price},- Kč měsíčně
                                    </Col>
                                </Row>
                                <Row>
                                    <h4 className={"text-center pb-4 text-center"}>Speciální úvítací nabídka!</h4>
                                    <Col xs={12} md={12} className={"mt-3"}>
                                        <ul>
                                            <li>Neomezený počet VIN reportů</li>
                                            <li>Neomezený swiper na nové inzeráty</li>
                                            <li>5x filtr na inzeráty s notifikacemi</li>
                                            <ul>
                                                <li>Přizpůsobte si své upozornění a získejte notifikace o nových inzerátech na internetu.</li>
                                            </ul>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                        <Button variant="success" type="submit">
                            Potvrdit platbu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}