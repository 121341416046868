import React, {useEffect, useState} from "react";
import Row from 'react-bootstrap/Row';
import Table, {formatDate} from "../table/Table";
import SubscriptionButton from "../subscription/SubscriptionButton";
import axios from "axios";
import {API_HOST} from "../App";
import Container from "react-bootstrap/Container";
import Col from 'react-bootstrap/Col';
import MainNavbar from "../navbar/Navbar";

interface FilterProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}

const FilterSettings: React.FC<FilterProps> = (props) => {

    const [data, setData] = useState([]);

    const removeFilter = async (filterID: any) => {
        let host = API_HOST;
        let filterRoute = "/filter";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {id: filterID}
        };
        await axios.delete(host + filterRoute, config);
        await fetchFilters()
    };

    let Columns = [
        {
            Header: "Filtry",
            columns: [
                {
                    Header: ({ cell }: { cell: any }) => (
                        <span style={{"color": "grey"}}>Smazat</span>),
                    accessor: 'Delete',
                    Cell: ({ cell }: { cell: any }) => (
                        <a style={{ color: "red", textDecoration: "none", cursor: "pointer" }}
                           onClick={() => removeFilter(cell.row.original.id)}
                           className={"btn-sm btn-primary"}>X</a>
                    )
                },
                {
                    name: "Jméno",
                    Header: "Jméno",
                    Cell: ({ cell }: { cell: any }) => (
                        <a href={`/filter/${cell.row.original.id}`} className={"color-primary-light"} style={{textDecoration: "none"}}>
                            {cell.row.original.filter_name}
                        </a>
)
},
    {
        name: "",
                    Header: "Inzeráty",
                    accessor: (row: any) => row.filter_id,
                    Cell: ({ cell }: { cell: any }) => (
                        <a href={`/filter/${cell.row.original.id}`} className={"color-primary-light"} style={{ textDecoration: "none" }}>
                            {`Detail`}
                        </a>
                    )
                }
            ]
        },
    ];

    const voidF = async () => {
    };

    const fetchFilters = async () => {
        let host = API_HOST;
        let filterRoute = "/filter/my";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };
        const response = await axios.get(host + filterRoute, config);
        let d = response.data;
        setData(d);
    };

    useEffect(() => {
        fetchFilters()
    }, []);

    return (
        <>
            <div className={"bg-light"}>
                <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
                <Container className={"bg-light min-vh-100"}>
                    <Row className={"mt-5 mb-5"}>
                        <Col>
                            <h1>Nastavení notifikací</h1>
                            <a href="/filter">zpět na moje Notifikace</a>
                        </Col>
                        <Col className={"text-right"}>
                            <a className={"btn btn-success"} href="/filter/new">+ Přidat notifikace</a>
                        </Col>
                    </Row>
                    <Table tableKey="my-filters" columns={Columns} data={data} selected={null} onSelectedChanged={voidF}/>
                </Container>
            </div>
        </>
    );
}

export default FilterSettings;
