import React, {Dispatch, SetStateAction} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdvertsQueryBuilderDisplay from "../qb/QueryBuilderDisplay";
import MainNavbar from "../navbar/Navbar";
import UserSubscription from "../App";
import Container from "react-bootstrap/Container";

interface FilterProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}



const Search: React.FC<FilterProps> = (props) => {

    return (
        <>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <AdvertsQueryBuilderDisplay userSubscription={props.userSubscription} setUserSubscription={props.setUserSubscription}></AdvertsQueryBuilderDisplay>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Search;
