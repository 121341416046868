import React, {Dispatch, SetStateAction} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MainNavbar from "../navbar/Navbar";
import FilterSettings from "./FilterSettings";
import FilterFeed from "./FilterFeed";
import UserSubscription from "../App";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";

interface FilterProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
}



const Filters: React.FC<FilterProps> = (props) => {

    return (
        <div>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container className={"min-vh-100"}>
                <Row className={"mt-4 mb-4"}>
                    <Col>
                        <h1>Moje notifikace</h1>
                    </Col>
                    <Col className={"text-right"}>
                        <a href="/filter/settings" className={"btn btn-success"}>Nastavení notifikací</a>
                    </Col>
                </Row>
                <FilterFeed userSubscription={props.userSubscription}></FilterFeed>
            </Container>
        </div>
    );
}

export default Filters;
