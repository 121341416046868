import React from "react";
import LoginForm from "../login/LogIn";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Logo from "../Logo";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";

interface HomepageProps {
    setLoggedIn: (loggedIn: boolean) => void;
}

const Homepage: React.FC<HomepageProps> = (props) => {

    return (
        <>
            <Container fluid className="min-vh-100 d-flex flex-column justify-content-center navbar-bg-custom">
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12} md={{span: 6, offset: 3}} className="text-center homepage-logo">
                                    <Logo></Logo>
                                </Col>
                                <Col xs={12} md={{span: 6, offset: 3}} className={"pt-5"}>
                                    <LoginForm setLoggedIn={props.setLoggedIn}></LoginForm>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h3 className={"p-2 pt-5 mt-5 text-racing color-secondary"}>Podrobný přehled trhu s vozidly přímo ve vaší kapse. Pomůžeme vám najít vysněné auto nebo winter káru.</h3>
                        </Col>
                    </Row>
                </Container>
                <Navbar fixed="bottom" className="navbar-bg-custom">
                    <Container className="justify-content-around navbar-size">
                        <Nav className="w-100 d-flex justify-content-around align-items-center navbar-bg-custom">
                            <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                                <Link to="/settings/voip" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                                    <span className="racing">Discord</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                                <Link to="/settings/voip" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                                    <span className="racing">Facebook</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                                <Link to="/settings/voip" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                                    <span className="racing">Email</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                                <Link to="/settings/voip" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                                    <span className="racing">Podmínky</span>
                                </Link>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar>
            </Container>
        </>
    );
}

export default Homepage;
