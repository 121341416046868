import React, {Dispatch, SetStateAction} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdvertsQueryBuilder from "../qb/QueryBuilder";
import MainNavbar from "../navbar/Navbar";
import UserSubscription from "../App";
import Container from "react-bootstrap/Container";

interface FilterProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}



const NewFilter: React.FC<FilterProps> = (props) => {

    return (
        <>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container>
            <Row>
                <Col xs={12}>
                    <Row>
                        <AdvertsQueryBuilder userSubscription={props.userSubscription} setUserSubscription={props.setUserSubscription}></AdvertsQueryBuilder>
                    </Row>
                </Col>
            </Row>
            </Container>
        </>
    );
}

export default NewFilter;
