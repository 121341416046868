import UserSubscription, {API_HOST, BMW_PRICE, FERRARI_PRICE, SKODA_PRICE} from "../App";
import React, {Dispatch, SetStateAction, useState} from "react";
import Col from "react-bootstrap/Col";
import QRModalButton from "../dashboard/QRModalButton";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import MainNavbar from "../navbar/Navbar";
import Container from "react-bootstrap/Container";
import Swiper from "../swiper/Swiper";

interface DashboardProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}

const SubscriptionButton: React.FC<DashboardProps> = (props) => {

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [qrURL, setQrUrl] = useState("");

    const closeModal = () => {
        setOpenModal(false);
    };

    const handleQRGenereate = async (amount: number, sub: string) => {
        let host = API_HOST;
        let subscriptionRoute = "/subscription/qr";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };

        try {
            let response = await axios.post(host + subscriptionRoute, {
                subscription: sub
            }, {    ...config,
                responseType: 'blob'});
            const url = URL.createObjectURL(new Blob([response.data]));
            setPrice(amount)
            setSubscription(sub)
            setQrUrl(url)
            setOpenModal(true)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Handle Axios error
                NotificationManager.error("problem s generovanim qr kodu");
            } else if (error instanceof Error) {
                // Handle generic errors
                NotificationManager.error(error.message);
            } else {
                // Handle unknown errors
                NotificationManager.error("An unknown error occurred");
            }
        }
    };

    function formatDate(dateString: string) {
        let date = new Date(dateString);

        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        let year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    function GetLogo(manufacturer: string) {
        return "/img/" + manufacturer.toLowerCase() + "-promo.png"
    }

    return (
        <>
                <QRModalButton showModal={openModal} qrURL={qrURL} closeModal={closeModal} subscription={subscription}
                         price={price} userSubscription={props.userSubscription}
                         setUserSubscription={props.setUserSubscription}></QRModalButton>
                <a style={{"color": "red", cursor: "pointer"}} onClick={() => handleQRGenereate(FERRARI_PRICE, "FERRARI")}>
                   zde
                </a>
        </>
    )
}

export default SubscriptionButton;