import React from 'react';

const FullScreenLoading = () => {
    return (
        <div className="fullscreen-loading-container">
            <div className="loading-content">
                <img src="/img/wheel.svg" alt="Loading wheel" className="loading-wheel" />
                <p className={"loading-text text-racing"}>Načítání</p>
            </div>
        </div>
    );
};

export default FullScreenLoading;
