import React, {useState, useEffect, useRef} from "react";
import Col from "react-bootstrap/Col";
import Logo from "../Logo";
import Row from "react-bootstrap/Row";
import axios from "axios";
import {Button, Container, Form} from "react-bootstrap";
import BasicInfo from "./BasicInfo";
import { useLocation } from 'react-router-dom';
import AdvertisementDetail from "./Advertisements";
import HistoryDetail from "./History";
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { NotificationManager } from "react-notifications";
import LoadingModal from "./LoadingModal";
import MainNavbar from "../navbar/Navbar";
import SubscriptionButton from "../subscription/SubscriptionButton";
import {API_HOST} from "../App";

export interface CarData {
    manufacturer: string;
    model: string;
    manufactured_year: number;
    manufactured_country: string;
    vin: string;
    seats: number;
    engine_power: number;
    engine_cylinders: number;
    engine_volume: number;
    engine_energy: string;
    engine_type: string;
    emmision_level_fullfilment: string;
    wheel_size_rear: string;
    manufactured_city: string;
    wheel_size_front: string;
    top_speed: number;
    transmission: string;
    body_style: string;
    wheel_drive: string;
    color: string;
    registration_cr: string;
    first_registration: string;
    fuel_consumption_urban: number;
    fuel_consumption_extra_urban: number;
    fuel_consumption_highway: number;
    car_length: number;
    car_width: number;
    car_height: number;
    car_weight: number;
    car_max_weight: number;
    advertisements: Advertisement[];
    mileage_records: MileageRecord[];
}

export interface Advertisement {
    created_at: Date;
    source: string;
    description: string;
    location: string;
    url: string;
    price: number;
    images: string[];
}

export interface MileageRecord {
    date: string;
    mileage: number;
    checkType: string;
    checkDescription: string;
    issues: string[];
    note: string
}



interface VinReportProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}



const VinReport: React.FC<VinReportProps> = (props) => {

    const location = useLocation();
    const { userSubscription, setUserSubscription } = location.state || {};

    if (location.state) {
        props.userSubscription = userSubscription;
        props.setUserSubscription = setUserSubscription
    }

    const { vinParam } = useParams();
    const [data, setData] = useState<CarData | null>(null);
    const [vin, setVin] = useState(vinParam || "");
    const [request, setRequest] = useState(false);

    const handleVin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVin(event.target.value);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        // @ts-ignore
        content: () => componentRef.current,
        documentTitle: vin,
    });

    const isVinValid = (vin: string) => {
        const vinRegex = /^[A-HJ-NPR-Z0-9]{17}$/;
        return vinRegex.test(vin);
    };

    const handleSubmit = async () => {

        if (!isVinValid(vin)) {
            NotificationManager.warning('Neplatné VIN');
            return;
        }

        setRequest(true)
        let host = API_HOST;
        let filterRoute = "/report";
        try {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            };
            let payload = {
                "vin": vin
            }

            const response = await axios.post(host + filterRoute, JSON.stringify(payload), config);

            let d = response.data;

            const carData = JSON.parse(JSON.stringify(d));
            setRequest(false);
            props.userSubscription.credit = props.userSubscription.credit - 1
            setData(carData);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // @ts-ignore
                if (error.response.status === 430) {
                    NotificationManager.warning('Nedostatek kreditú');
                }
            } else {
                // NotificationManager.error(error);
                console.log(error)
            }

            setRequest(false)
        }
    };

    useEffect(() => {
        setVin(vinParam || "");
        if (vinParam) {
            handleSubmit();
        }
    }, [vinParam]);

    return (
        <>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container className={"container-report"}>
            {request ?
                <LoadingModal vin={vin}></LoadingModal>
                :
                <>
                <Row className={"text-center"}>
                    <Col className={"pt-3"} xs={12} md={{span: 6, offset: 3}}>
                        <Row>
                            <Col><h1 className={"pb-1"}>Vin Report</h1></Col>
                        </Row>
                        <Row>
                            {props.userSubscription && (
                                <>
                                    <p className={"pb-1"}>
                                        Reporty k dispozici: {props.userSubscription.credit}
                                    </p>
                                    {props.userSubscription.credit <= 0 && (
                                        <p>
                                            Zakupte si predplatné na reporty{" "}
                                            <SubscriptionButton
                                                setUserSubscription={props.setUserSubscription}
                                                userSubscription={props.userSubscription}
                                            ></SubscriptionButton>
                                            .
                                        </p>
                                    )}
                                </>
                            )}
                        </Row>
                        <Row>
                            <Col>

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="vin">
                                        <Form.Control
                                            type="text"
                                            name="vin"
                                            value={vinParam}
                                            placeholder={"VIN"}
                                            onChange={handleVin}
                                            required
                                        />
                                    </Form.Group>
                                </Form>

                            </Col>
                            <Col>
                                <Button variant="success" onClick={handleSubmit}>
                                    Generovat
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            {data &&
                <Button variant="secondary" className={"mb-5 mt-5"} onClick={handlePrint}>
                Vytisknout report
                </Button>}
            {data &&
            <Row className={"vin-report"} ref={componentRef}>
                <Col className={"report-header-logo"}>
                    <Logo></Logo>
                </Col>
                <BasicInfo carData={data}></BasicInfo>
                <hr/>
            {data.advertisements && data.advertisements.length > 0 &&
                <Row className={"report-body"}>
                <Col><h2>Inzerce</h2></Col>
                </Row>
            }
            {data.advertisements &&
                data.advertisements
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a: Advertisement, b: Advertisement) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) // Sort by date
                    .map((advertisement: Advertisement, i: number) => {
                        return <AdvertisementDetail key={i} advertisement={advertisement} />;
                    })}
                    <hr/>
            {data.mileage_records &&
                <>
                <Row className={"report-body"}>
                <Col><h2>Historie</h2></Col>
                </Row>
                <HistoryDetail data={data.mileage_records}></HistoryDetail>
                </>
            }
                </Row>
            }
                </>}
            </Container>
        </>
    );
}

export default VinReport;
