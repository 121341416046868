import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import TokenForm from "../login/Token";
import {Button, Card, Carousel, Col, Row, Container, Navbar, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logo from "../Logo";
import axios, { AxiosError } from 'axios';



const discordClientId = "1214192063080570890";

interface LoginProps {
    setLoggedIn: (loggedIn: boolean) => void;
}

const RegisterToken: React.FC<LoginProps> = (props: LoginProps) => {

    let { token } = useParams();

    if (!token) {
        token = ""
    }

    return (
        <>
            <Container fluid className="min-vh-100 d-flex flex-column justify-content-center navbar-bg-custom">
                <Container>
                    <Row>
                        <Col xs={12} md={{span: 6, offset: 3}} className="text-center homepage-logo">
                            <Logo></Logo>
                        </Col>
                        <Col xs={12} md={{span: 6, offset: 3}} className={"pt-5"}>
                            <TokenForm token={token} setLoggedIn={props.setLoggedIn}></TokenForm>
                        </Col>
                    </Row>
                </Container>
                <Navbar fixed="bottom" className="navbar-bg-custom">
                    <Container className="justify-content-around navbar-size">
                        <Nav className="w-100 d-flex justify-content-around align-items-center navbar-bg-custom">
                            {/* Swiper Nav Item */}
                            <Nav.Item className="d-flex flex-column align-items-center justify-content-center">
                                <Link to="/settings/voip" className="nav-link color-secondary d-flex flex-column align-items-center justify-content-center">
                                    <span className="racing">Obchodní podmínky</span>
                                </Link>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar>
            </Container>
        </>
    );
}

export default RegisterToken;
