import React, { useState, useEffect } from "react";
import { Ad } from "../filters/FilterDetail";
import { API_HOST } from "../App";
import axios, { AxiosError } from 'axios';
import SwiperDetail from "./SwiperDetail";
import { NotificationManager } from "react-notifications";
import FullScreenLoading from "../loading/FullScreenLoading";
import SubscriptionButton from "../subscription/SubscriptionButton";
import EmptyGarage from "../loading/EmptyGarage";

interface OutOfQuotaProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
    timeRemaining: number
}

const OutOfQuota: React.FC<OutOfQuotaProps> = (props) => {

    return (
        <>
            <div className="eg-container">
                <div className="eg-content">
                    <img src="/img/empty-fuel.png" alt="garage-empty" width={"50%"}/>
                    <p className={"eg-text"}>Palivo vyčerpáno, zakupte si neomezené palivo <SubscriptionButton userSubscription={props.userSubscription} setUserSubscription={props.setUserSubscription}></SubscriptionButton>.</p>
                    {props.timeRemaining !== 0 && <p className={"eg-text"}><>Nebo vyčkejte {props.timeRemaining} sekund</></p>}
                </div>
            </div>
        </>
    );
};

export default OutOfQuota;
