import React, {useState, useCallback, useEffect, SetStateAction, Dispatch} from "react";
import MainNavbar from "../navbar/Navbar";
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {NotificationManager} from "react-notifications";
import QRModal from "./QRModal";
import UserSubscription, {API_HOST, BMW_PRICE, FERRARI_PRICE, SKODA_PRICE} from "../App";
import Container from "react-bootstrap/Container";
import Swiper from "../swiper/Swiper";

interface DashboardProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}



const Dashboard: React.FC<DashboardProps> = (props) => {

    return (
        <div>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container>
                <Row className={"mt-2"}>
                    <Col md={{span: 10, offset: 1}}>
                        <Swiper setUserSubscription={props.setUserSubscription} userSubscription={props.userSubscription}></Swiper>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Dashboard;
