import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Form, Row, Col, Tab, Tabs} from 'react-bootstrap';
import { NotificationManager } from "react-notifications";
import AdvertisementDetail from "../filters/AdvertisementDetail";
import Ad from "../filters/FilterDetail"
import FullScreenLoading from "../loading/FullScreenLoading";
import {API_HOST} from "../App";
import UserSubscription from "../App";
import SubscriptionButton from "../subscription/SubscriptionButton";

interface CarListing {
    id: number;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string | null;
    vin: string;
    url: string;
    source: string;
    vin_complete: boolean;
    description: string;
    media: any[] | null; // You may want to define a more specific type for media
    mileage_km: number;
    price_currency: string;
    price_value: number;
    seller_name: string;
    seller_email: string;
    seller_phone: string;
    seller_city: string;
    seller_district: string;
    seller_country: string;
    drivetrain: string;
    transmission: string;
    manufacturer: string;
    engine_energy: string;
    engine_volume: number;
    engine_power_kw: number;
    body_style: string;
    manufactured_year: number;
    model: string;
    color: string;
    raw_equipment: string; // This should be a JSON string, but you may want to parse it to a specific structure
}

interface ManufacturerModels {
    manufacturer: string;
    models: string[];
}

interface FormFields {
    manufacturerModels: ManufacturerModels[],
    mileage: number,
    price: number,
    fuelType: string[],
    transmission: string[],
    drivetrain: string[],
    bodyStyles: string[],
}

interface ManufacturerModelsDict {
    [manufacturer: string]: string[];
}

interface QBProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
    // @ts-ignore
    setUserSubscription: Dispatch<SetStateAction<UserSubscription | null>>;
}

type NotificationType = 'EMAIL' | 'DISCORD_DM';

const AdvertsQueryBuilder: React.FC<QBProps> = (props) => {
    // State hooks for form fields
    const [filterName, setFilterName] = useState<string | null>(null);
    const [manufacturer, setManufacturer] = useState<string | null>(null);
    const [model, setModel] = useState<string | null>(null);
    const [transmission, setTransmission] = useState<string | null>(null);
    const [selectedNotificationType, setSelectedNotificationType] = useState<NotificationType>('EMAIL');
    const [fuelType, setFuelType] = useState<string | null>(null);
    const [formFields, setFormFields] = useState<FormFields | null>(null);
    const [selectedManufacturer, setSelectedManufacturer] = useState<string | null>(null);
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const [priceUpperLimit, setPriceUpperLimit] = useState<number | null>(null);
    const [priceLowerLimit, setPriceLowerLimit] = useState<number | null>(null);
    const [mileageUpperLimit, setMileageUpperLimit] = useState<number | null>(null);
    const [mileageLowerLimit, setMileageLowerLimit] = useState<number | null>(null);
    const [powerLowerLimit, setPowerLowerLimit] = useState<number | null>(null);
    const [powerUpperLimit, setPowerUpperLimit] = useState<number | null>(null);
    const [manufacturedDateUpperLimit, setManufacturedDateUpperLimit] = useState<number | null>(null);
    const [manufacturedDateLowerLimit, setManufacturedDateLowerLimit] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFilterNameTouched, setIsFilterNameTouched] = useState(false);
    // @ts-ignore
    const [filterCars, setFilterCars] = useState<Ad[] | null>(null);
    type TabKey = 'cart' | 'checkout' | 'notifications';
    const [key, setKey] = useState<TabKey>('cart');
    let padding = 1

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedNotificationType(event.target.value as NotificationType);
    };

    const handleManufacturerChange = (event: React.ChangeEvent<any>) => {
        setSelectedManufacturer(event.target.value);
        setSelectedModel(null); // Reset model selection
    };

    const handleModelChange = (event: React.ChangeEvent<any>) => {
        setSelectedModel(event.target.value);
    };

    const handleFormChange = (event: React.ChangeEvent<any>) => {
        const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;

        switch (name) {
            case 'Jméno filtru':
                // Set the yearFrom state
                setFilterName(value ? value : null);
                break;
            case 'Rok od':
                // Set the yearFrom state
                setManufacturedDateLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Rok do':
                // Set the yearTo state
                setManufacturedDateUpperLimit(value ? parseInt(value) : null);
                break;
            case 'Cena od':
                // Set the price state
                setPriceLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Cena do':
                // Set the price upper limit state
                // Assuming there's a state variable for the upper limit of price
                setPriceUpperLimit(value ? parseInt(value) : null);
                break;
            case 'Km od':
                // Set the mileage state
                setMileageLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Km do':
                // Set the mileage upper limit state
                // Assuming there's a state variable for the upper limit of mileage
                setMileageUpperLimit(value ? parseInt(value) : null);
                break;
            case 'Výkon od':
                // Set the power lower limit state
                // Assuming there's a state variable for the lower limit of power
                setPowerLowerLimit(value ? parseInt(value) : null);
                break;
            case 'Výkon do':
                // Set the power upper limit state
                // Assuming there's a state variable for the upper limit of power
                setPowerUpperLimit(value ? parseInt(value) : null);
                break;
            case 'fuelType':
                // Set the fuelType state
                setFuelType(value);
                break;
            case 'transmission':
                // Set the transmission state
                setTransmission(value);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        async function SetFields() {
            let host = API_HOST;
            let filterRoute = "/filter";
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            };
            const response = await axios.get(host + filterRoute, config);

            // @ts-ignore
            let fields: FormFields = {}
            // @ts-ignore
            let manufacturerModels: ManufacturerModelsDict = {};

            response.data.forEach((item: { manufacturer: string; model: string; }) => {
                if (!manufacturerModels[item.manufacturer]) {
                    manufacturerModels[item.manufacturer] = [];
                }
                manufacturerModels[item.manufacturer].push(item.model);
            });

            fields.manufacturerModels = []
            Object.keys(manufacturerModels).forEach(manufacturer => {
                fields.manufacturerModels.push(
                    {manufacturer: manufacturer, models: manufacturerModels[manufacturer]} as ManufacturerModels
                )
            });


            fields.fuelType = [
                "CNG",
                "DIESEL",
                "ELECTRIC",
                "HYBRIDE",
                "HYDROGEN",
                "LPG",
                "PETROL"
            ];
            fields.transmission = [
                "AT",
                "MT",
            ];

            fields.drivetrain = ["AWD", "FWD", "RWD"]
            setIsLoading(false)
            setFormFields(fields);
        }

        SetFields();

    }, []);

    const buildPayload = () => {
        const query: any = {};

        if (selectedManufacturer) {
            query.manufacturer = selectedManufacturer;
        }

        if (selectedModel) {
            query.model = selectedModel;
        }

        if (transmission) {
            query.transmission = transmission;
        }

        if (fuelType) {
            query.engine_energy = fuelType;
        }

        if (priceLowerLimit !== null || priceUpperLimit !== null) {
            query.price_value = {};
            if (priceLowerLimit !== null) {
                query.price_value.$gte = priceLowerLimit;
            }
            if (priceUpperLimit !== null) {
                query.price_value.$lte = priceUpperLimit;
            }
        }

        if (mileageLowerLimit !== null || mileageUpperLimit !== null) {
            query.mileage_km = {};
            if (mileageLowerLimit !== null) {
                query.mileage_km.$gte = mileageLowerLimit;
            }
            if (mileageUpperLimit !== null) {
                query.mileage_km.$lte = mileageUpperLimit;
            }
        }

        if (powerLowerLimit !== null || powerUpperLimit !== null) {
            query.engine_power_kw = {};
            if (powerLowerLimit !== null) {
                query.engine_power_kw.$gte = powerLowerLimit;
            }
            if (powerUpperLimit !== null) {
                query.engine_power_kw.$lte = powerUpperLimit;
            }
        }

        if (manufacturedDateLowerLimit !== null || manufacturedDateUpperLimit !== null) {
            query.manufactured_year = {};
            if (manufacturedDateLowerLimit !== null) {
                query.manufactured_year.$gte = manufacturedDateLowerLimit;
            }
            if (manufacturedDateUpperLimit !== null) {
                query.manufactured_year.$lte = manufacturedDateUpperLimit;
            }
        }

        return query
    }

    const handleSubmit = (event: React.FormEvent<any>) => {
        event.preventDefault();

        if (!filterName) {
            NotificationManager.warning("Prosím vyplňte jméno filtru", "Chybějící Jméno filtru");
            setIsFilterNameTouched(true); // Set the flag to true
            return;
        }

        let filterQuery = buildPayload()

        if (Object.keys(filterQuery).length < 2) {
            NotificationManager.warning("Vyberte aspoň 2 parametry");
            return;
        }

        var payload = {
            "filter_name": filterName,
            "filter": JSON.stringify(buildPayload()),
            "hook_type": selectedNotificationType,
        }

        let host = API_HOST;
        let filterRoute = "/filter";

        const config = {
            headers:{
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };

        axios.post(host + filterRoute, JSON.stringify(payload), config)
            .then(function (response) {
                NotificationManager.info("Filter pridan")
                window.location.href='/filter'
            })
            .catch(function (error) {
                if (axios.isAxiosError(error)) {
                    if (!error.response || error.response.status === 421) {
                        NotificationManager.error("Limit filtru dosažen, odoberte filtry, nebo zakupte vyšší předplatné.");
                    } else {
                        NotificationManager.error(error.response?.data?.message || "An error occurred");
                    }
                } else if (error instanceof Error) {
                    // Handle generic errors
                    NotificationManager.error(error.message);
                } else {
                    // Handle unknown errors
                    NotificationManager.error(error.response?.data?.message || "An error occurred");
                }
            });

    };

    const handleDisplayCars = (event: React.FormEvent<any>) => {

        event.preventDefault();

        if (!filterName) {
            NotificationManager.warning("Prosím vyplňte jméno filtru", "Chybějící Jméno filtru");
            setIsFilterNameTouched(true); // Set the flag to true
            return;
        }

        let filterQuery = buildPayload()

        if (Object.keys(filterQuery).length < 2) {
            NotificationManager.warning("Vyberte aspoň 2 parametry");
            return;
        }

        setKey("checkout")
        setIsLoading(true)
        var payload = {
            "filter": JSON.stringify(buildPayload())
        }

        let host = API_HOST;
        let filterRoute = "/filter/cars";

        const config = {
            headers:{
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };

        axios.post(host + filterRoute, JSON.stringify(payload), config)
            .then(function (response) {
                setFilterCars(response.data)
                setIsLoading(false)
            })
            .catch(function (error) {
                NotificationManager.error(error.response?.data?.message || "An error occurred");
                setIsLoading(false)
            });

    };

    console.log(props.userSubscription)

    return (
        <>
            {(!isLoading && formFields) ? (
            <Row className={"bg-light qb-background pt-2 pb-2"}>
                <Tabs
                    id="shopping-cart-tabs"
                    activeKey={key}
                    onSelect={(k) => k && setKey(k as TabKey)}
                    className="mb-3"
                >
                    <Tab eventKey="cart" title="Formulář">
                        <Form onSubmit={handleSubmit}>
                            <h2 className={'pb-2 pt-2'}>Nové notifikace</h2>
                            <p>
                                Tento formulář vám umožní snadno vytvořit nový filtr pro nastavení hledání vozidel podle vašich kritérií. Specifikujte různé parametry vyhledávání, aby perfektně odpovídaly vašim požadavkům. Díky tomu najdete vozidlo, které hledáte, rychle a efektivně.
                            </p>
                            <hr/>
                            <>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group controlId="manufacturer">
                                            <Form.Label>Výrobce</Form.Label>
                                            <Form.Control as="select" value={selectedManufacturer ?? ''} onChange={handleManufacturerChange}>
                                                <option value="">Výrobce</option>
                                                {formFields.manufacturerModels.map(({ manufacturer }) => (
                                                    <option key={manufacturer} value={manufacturer}>
                                                        {manufacturer}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr/>
                                {selectedManufacturer && (
                                    <>
                                        <Row className={"mb-1"}>
                                            <Col md={12}>
                                                <Form.Group controlId="model">
                                                    <Form.Label>Model</Form.Label>
                                                    <Form.Control as="select" value={selectedModel ?? ''} onChange={handleModelChange}>
                                                        <option value="">Model</option>
                                                        {formFields.manufacturerModels
                                                            .find(({ manufacturer }) => manufacturer === selectedManufacturer)
                                                            ?.models.map(model => (
                                                                <option key={model} value={model}>
                                                                    {model}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </>
                                )}
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="yearFrom">
                                                    <Form.Label>Rok od</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Rok od"
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="yearTo">
                                                    <Form.Label>Rok do</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Rok do"
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="priceFrom">
                                                    <Form.Label>Cena od</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Cena od"
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="priceTo">
                                                    <Form.Label>Cena do</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Cena do"
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="mileageFrom">
                                                    <Form.Label>Km od</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Km od"
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="mileageTo">
                                                    <Form.Label>Km do</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="Km do"
                                                        onChange={handleFormChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr/><Row className={"mb-1"}>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="powerForm">
                                                <Form.Label>Výkon od (kW)</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="Výkon od"
                                                    onChange={handleFormChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="powerTo">
                                                <Form.Label>Výkon do (kW)</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="Výkon do"
                                                    onChange={handleFormChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Palivo</Form.Label>
                                            <Form.Control as="select" name="fuelType" onChange={handleFormChange}>
                                                <option value="">Palivo</option>
                                                {formFields.fuelType.map(fuel => (
                                                    <option key={fuel} value={fuel}>
                                                        {fuel}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>Převodovka</Form.Label>
                                            <Form.Control as="select" name="transmission" onChange={handleFormChange}>
                                                <option value="">Převodovka</option>
                                                {formFields.transmission.map(transmission => (
                                                    <option key={transmission} value={transmission}>
                                                        {transmission}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className={"mb-1"}>
                                    <Col md={12}>
                                        <Form.Group controlId="filterName">
                                            <Form.Label>Jméno filtru *</Form.Label>
                                            <Form.Control
                                                name="Jméno filtru"
                                                onChange={handleFormChange}
                                                style={isFilterNameTouched && !filterName ? { borderColor: 'red' } : {}}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col xs={12} className={"btn-right text-center pt-5 pb-5 mb-5"}>
                                    <Button variant="info"  onClick={handleDisplayCars} type="submit">Otestuj filter</Button>
                                </Col>
                            </>
                        </Form>
                    </Tab>
                    <Tab eventKey="checkout" title="Ověření">
                        <h2 className={'pb-2 pt-2'}>Ověření filtru</h2>
                        <p>Prohlédněte si inzeráty vozidel, které jsme pro vás vyfiltrovali
                            podle vašich kritérií. Nově přidané inzeráty vám neuniknou, protože vám o nich pošleme
                            notifikaci. Podívejte se, jestli inzeráty odpovídají tomu, co hledáte, a pokud ano,
                            jednoduše potvrďte váš filtr. Takto zajistíte, že budete vždy informováni o nejnovějších
                            nabídkách odpovídajících vašim požadavkům.</p>
                        <hr/>
                        <Col xs={12} className={"text-center"}>
                            <Button variant="success" onClick={() => setKey('notifications')} type="submit">Potvrdit
                                filtr</Button>
                        </Col>
                        <hr/>
                        {filterCars && filterCars.map((ad, index) => (
                            <>
                                <AdvertisementDetail advertisement={ad}></AdvertisementDetail>
                                <Row className={"mt-2 mb-2"}></Row>
                            </>
                        ))}
                    </Tab>
                    <Tab eventKey="notifications" title="Notifkace">
                        <h2 className={'pb-2 pt-2'}>Nastevení notifikací</h2>
                        <p>Vyberte si, jakým způsobem chcete být informováni, když na inzertní portál přibude nový
                            inzerát. Máte možnost nastavit preferovaný typ notifikace, abyste byli vždy včas upozorněni
                            na nové nabídky.</p>
                        <hr/>
                        <Col xs={12} md={6}  className="border-custom">
                            <Form>
                                <Form.Check
                                    type="radio"
                                    id="email"
                                    label={"Email (" + localStorage.getItem("email") + ")"}
                                    value="EMAIL"
                                    name="notificationType"
                                    checked={selectedNotificationType === 'EMAIL'}
                                    onChange={handleChange}
                                    className={"radio-sub"}
                                />
                                <Form.Check
                                    type="radio"
                                    id="discord"
                                    label={(props.userSubscription && props.userSubscription.on_discord) ? "Discord" : "Discord (chybí přepojení)"}
                                    value="DISCORD_DM"
                                    disabled={props.userSubscription && !props.userSubscription.on_discord}
                                    name="notificationType"
                                    checked={selectedNotificationType === 'DISCORD_DM'}
                                    onChange={handleChange}
                                    className={"radio-sub"}
                                />
                            </Form>
                        </Col>
                        <Col className={"text-center mt-5 mb-5"}>
                            <Button variant="success" onClick={handleSubmit} type="submit">Uložit filtr</Button>
                        </Col>
                    </Tab>
                </Tabs>
                <Col md={8} className={"p-6"}>
                    {filterCars ? (
                        <Row className={"bg-light pt-4"}>
                            <Col>

                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
            ) : <FullScreenLoading />}
        </>
    );
};

export default AdvertsQueryBuilder;
