import React, { useState } from "react";
import UserSubscription, { API_HOST, BMW_PRICE, FERRARI_PRICE, SKODA_PRICE } from "../App";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import MainNavbar from "../navbar/Navbar";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

interface SettingsProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
}

const Settings: React.FC<SettingsProps> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState("");

    function formatDate(dateString: string) {
        let date = new Date(dateString);

        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        let year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    const generateQR = async () => {
        try {
            const config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                responseType: 'blob'
            };
            const response = await axios.get(`${API_HOST}/subscription/token`, {...config,
                responseType: 'blob'});
            if (response.status === 200) {
                const qrCodeBlob = new Blob([response.data], { type: 'image/png' });
                const qrCodeUrl = URL.createObjectURL(qrCodeBlob);
                setQrCodeUrl(qrCodeUrl);
                setShowModal(true);
            } else {
                NotificationManager.error("Failed to generate QR code");
            }
        } catch (error) {
            NotificationManager.error("An error occurred while generating the QR code");
            console.log(error)
        }
    }

    return (
        <div className={"bg-light"}>
            <MainNavbar userSubscription={props.userSubscription}></MainNavbar>
            <Container className={"bg-light min-vh-100"}>
                <Col className="user-subscription-details">
                    <ul className="list-unstyled mt-3">
                        <li>{props.userSubscription?.email}</li>
                        <li>Úroveň: {props.userSubscription?.subscription ? props.userSubscription.subscription : "FREE"}</li>
                        <li>Platný do: {props.userSubscription?.subscription_validity_until && formatDate(props.userSubscription.subscription_validity_until)}</li>
                        <li>Reporty k dispozici: {props.userSubscription?.credit}</li>
                    </ul>
                </Col>

                <Row>
                    <Col className={"settings-item"}><Link to="/settings/subscription">Předplatné</Link></Col>
                </Row>
                <Row>
                    <Col className={"settings-item"}><Link to="/settings/voip">Obchodní podmínky</Link></Col>
                </Row>
                {(localStorage.getItem("email") === "matejsoroka@icloud.com" || localStorage.getItem("email") === "davidsevcik1@seznam.cz") &&
                    <Row>
                        <Col className={"settings-item"}><a onClick={generateQR}>Vygenerovat invite QR kód</a></Col>
                    </Row>
                }
                <Row>
                    <Col className={"settings-item"}><Link style={{"color": "red"}} to="/logout">Odhlášení</Link></Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className={"text-center text-racing"}>Car Notifier promokód</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" className="img-fluid" />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Settings;
