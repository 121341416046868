import React from "react";
import logo from './logo-header.png'
import { Col } from 'react-bootstrap';


const Logo: React.FC = () => {

    return (
        <Col>
            <h1 className={"color-secondary"}>Car Notifier</h1>
        </Col>
    )
}
export default Logo;