import React, {useEffect, useState} from 'react';
import './App.css';
import LoginForm from "./login/LogIn";
import {useJwt} from "react-jwt";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LogoutForm from "./login/LogOut";
import Home from "./homepage/Homepage";
import {NotificationContainer, NotificationManager} from "react-notifications";
import 'react-notifications/lib/notifications.css';
import Filters from "./filters/Filters";
import VinReport from "./reports/VinReport";
import Subscription from "./subscription/Subscription";
import Settings from "./settings/Settings";
import SettingsConditions from "./settings/Conditions";
import Dashboard from "./dashboard/Dashboard";
import axios from "axios";
import FilterDetail from "./filters/FilterDetail";
import NewFilter from "./filters/NewFilter";
import DiscordRedirect from "./login/Discord";
import FilterSettings from './filters/FilterSettings';
import FullScreenLoading from './loading/FullScreenLoading';
import RegisterToken from './registration/RegistrationToken';
import Search from './search/Search';

interface Jwt {
    id: string
}

export interface UserSubscription {
    subscription: string;
    subscription_validity_until: string;
    id: number;
    on_discord: boolean;
    credit: number;
}

export const SKODA_PRICE = 199
export const BMW_PRICE = 399
export const FERRARI_PRICE = 249

export const API_HOST = "https://api.carnotifier.eu"
export const WEB_HOST = "https://carnotifier.eu"

// export const API_HOST = "http://localhost"
// export const WEB_HOST = "http://localhost:3000"

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    let tkn = localStorage.getItem("token") ?? "";
    const { decodedToken, isExpired } = useJwt<Jwt>(tkn);
    const [loading, setLoading] = useState(true);
    const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null);

    const fetchSubscription = async () => {
        let host = API_HOST;
        let filterRoute = "/subscription";
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };

        try {
            const response = await axios.get(host + filterRoute, config);
            const userSub = response.data;
            setUserSubscription(userSub);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (axios.isAxiosError(error)) {
                if (!error.response || error.response.status === 404) {
                    setUserSubscription(null);
                } else {
                    // Handle other Axios errors
                }
            } else if (error instanceof Error) {
                NotificationManager.error(error.message);
            } else {
                NotificationManager.error("An unknown error occurred");
            }
        }
    };

    useEffect(() => {
        if (decodedToken && !isExpired) {
            localStorage.setItem("email", decodedToken.id);
            fetchSubscription()
            setLoggedIn(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [decodedToken, isExpired]);

    const showHomepage = !loggedIn;

    return (
        <Router>
            <div className="App">
                <NotificationContainer />
                    {loading ?
                        <FullScreenLoading></FullScreenLoading>
                        :
                        <>
                            {showHomepage ? (
                                <>
                                    <Routes>
                                        <Route path="/login/discord" element={<DiscordRedirect setLoggedIn={setLoggedIn}/>}/>
                                        <Route path="/logout" element={<LogoutForm setLoggedIn={setLoggedIn}/>}/>
                                        <Route path="/settings/voip" element={<SettingsConditions />}/>
                                        <Route path="/register/:token" element={<RegisterToken setLoggedIn={setLoggedIn} />}/>
                                        <Route path="*" element={<Home setLoggedIn={setLoggedIn} />} />
                                    </Routes>
                                </>
                            ) : (
                                <>
                                    <Routes>
                                        <Route path="/login" element={<LoginForm setLoggedIn={setLoggedIn} />} />
                                        <Route path="/logout" element={<LogoutForm setLoggedIn={setLoggedIn}/>} />
                                        <Route path="/filter" element={<Filters userSubscription={userSubscription} />} />
                                        <Route path="/search" element={<Search userSubscription={userSubscription} setUserSubscription={setUserSubscription} />} />
                                        <Route path="/filter/new" element={<NewFilter userSubscription={userSubscription}  setUserSubscription={setUserSubscription} />} />
                                        <Route path="/subscriptions" element={<Dashboard setUserSubscription={setUserSubscription} userSubscription={userSubscription} />} />
                                        <Route path="/filter/:id" element={<FilterDetail userSubscription={userSubscription}/>} />
                                        <Route path="/filter/settings" element={<FilterSettings userSubscription={userSubscription} setUserSubscription={setUserSubscription}/>} />
                                        <Route path="/report/:vinParam?" element={<VinReport userSubscription={userSubscription} setUserSubscription={setUserSubscription} />}/>
                                        <Route path="/login/discord" element={<DiscordRedirect setLoggedIn={setLoggedIn}/>}/>
                                        <Route path="/settings/subscription" element={<Subscription setUserSubscription={setUserSubscription} userSubscription={userSubscription}/>}/>
                                        <Route path="/settings" element={<Settings userSubscription={userSubscription}/>}/>
                                        <Route path="/settings/voip" element={<SettingsConditions />}/>
                                        <Route path="/logout" element={<LogoutForm setLoggedIn={setLoggedIn}/>} />
                                        <Route path="/" element={<Dashboard setUserSubscription={setUserSubscription} userSubscription={userSubscription} />} />
                                        <Route path="*" element={<Dashboard setUserSubscription={setUserSubscription} userSubscription={userSubscription} />} />
                                    </Routes>
                                </>
                            )}
                        </>
                    }
            </div>
        </Router>
    );
}

export default App;
