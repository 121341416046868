import React, {Dispatch, SetStateAction, useState, useEffect} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NotificationManager } from "react-notifications";
import AdvertsQueryBuilder from "../qb/QueryBuilder";
import UserSubscription from "../App";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import {Ad} from "../filters/FilterDetail"
import {API_HOST} from "../App";
import EmptyGarage from "../loading/EmptyGarage";
import FullScreenLoading from "../loading/FullScreenLoading";
import AdvertisementDetail from "../filters/AdvertisementDetail";

interface FilterProps {
    // @ts-ignore
    userSubscription: UserSubscription | null;
}



const FilterFeed: React.FC<FilterProps> = (props) => {

    const [filterCars, setFilterCars] = useState<Ad[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleDisplayCars = () => {

        let host = API_HOST;
        let filterRoute = "/filter/user";

        const config = {
            headers:{
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        };

        // @ts-ignore
        axios.get(host + filterRoute, config)
            // @ts-ignore
            .then(function (response) {
                setFilterCars(response.data)
                setIsLoading(false)
            })
            // @ts-ignore
            .catch(function (error) {
                NotificationManager.error(error.response?.data?.message || "An error occurred");
                setIsLoading(false)
            });

    };

    useEffect(() => {
        handleDisplayCars()
    }, []);

    return (
        <>
            <Row>
                {isLoading ?
                    <>
                        <FullScreenLoading></FullScreenLoading>
                    </>
                    :
                    <>
                        {(filterCars && filterCars.length) ? filterCars.map((ad, index) => (
                                <>
                                    <AdvertisementDetail advertisement={ad}></AdvertisementDetail>
                                    <Row className={"mt-2 mb-2"}></Row>
                                </>
                            ))
                            :
                            <EmptyGarage></EmptyGarage>
                        }
                    </>
                }
            </Row>
        </>
    );
}

export default FilterFeed;
