import React, {useState, useEffect, useRef} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Chart from 'chart.js/auto';
import {MileageRecord} from "./VinReport";

interface AggregatedMileageData {
    [year: number]: number;
}

function GetImageByResult(result: string, numberOfIssues: number) {

    let successImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Eo_circle_green_white_checkmark.svg/2048px-Eo_circle_green_white_checkmark.svg.png"
    let errorImage = "https://t3.ftcdn.net/jpg/01/43/11/20/360_F_143112044_gPXDDV55GTU8LQcX9GMbbP2Ss83ORMoy.jpg"
    let warningImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_alert-yellow.svg/2048px-OOjs_UI_icon_alert-yellow.svg.png"

    switch (result) {
        case "způsobilé":
        case "vyhovuje":
        case "":
            if (numberOfIssues > 1) {
                return warningImage
            } else {
                return successImage
            }
        default:
            return errorImage
    }
}

function GetCheckType(check: string) {
    if (check === "regular_maintenance") {
        check = "Pravidelný servis"
    } else if (check === "dealer_visit") {
        check = "Návštěva dealera"
    } else if (check === "start_of_warranty") {
        check = "Začátek záruky"
    }
    return check
}

function DetectFraud(mileageSequence: number[]) {
    var previous = 0;
    let fraud = false
    mileageSequence.forEach(function (number) {
        if (previous > number) {
            fraud = true
        }
        previous = number
    });
    return fraud
}

function formatDate(dateString: string) {
    let date = new Date(dateString);

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

function sortMileageRecordsByDate(mileageRecords: MileageRecord[]): MileageRecord[] {
    return mileageRecords.sort((b, a) => new Date(a.date).getTime() - new Date(b.date).getTime());
}

const HistoryDetail: React.FC<{ data: MileageRecord[] }> = ({data}) => {

    const [dates, setDates] = useState<any[] | null>(null);
    const [mileageValues, setMileageValues] = useState<any[] | null>(null);
    const [fraud, setFraud] = useState<boolean>(true);
    const chart = useRef<any>(null);

    let r = (Math.random() + 1).toString(36).substring(7);

    let sortedData = sortMileageRecordsByDate(data)

    useEffect(() => {

        const mileageData = data.map(entry => ({
            date: entry.date,
            mileage: entry.mileage,
        }));

        const aggregatedData: AggregatedMileageData = mileageData.reduce((acc, entry) => {
            const mileageDate = new Date(entry.date);
            const yearMonth = parseInt(`${mileageDate.getFullYear()}${String(mileageDate.getMonth() + 1).padStart(2, '0')}`);
            if (entry.mileage) {
                acc[yearMonth] = entry.mileage;
            }
            return acc;
        }, {} as AggregatedMileageData);

        setDates(Object.keys(aggregatedData).map(Number));
        setMileageValues(Object.values(aggregatedData));

        let fraud = DetectFraud(Object.values(aggregatedData))
        setFraud(fraud)

    }, [data]);

    useEffect(() => {
        // Create a chart when years and mileageValues are available
        if (dates && mileageValues) {
            const ctx = document.getElementById(r) as HTMLCanvasElement | null;

            if (ctx) {

                if (chart.current) {
                    chart.current.destroy();
                }

                chart.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: dates.map(String),
                        datasets: [{
                            label: 'Kilometry v čase',
                            data: mileageValues,
                            backgroundColor: fraud ? 'rgb(255,0,0)' : 'rgb(0,66,37)',
                            borderColor: fraud ? 'rgb(255,0,0)' : 'rgb(0,66,37)',
                            borderWidth: 1,
                        }],
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    },
                });
            }
        }
    }, [dates, mileageValues]);

    return (
        <>
            <Row className={"report-body"}>
                <Col>
                    {fraud &&
                        <Row>
                            <Col>
                                <p><img width={30} src="https://t3.ftcdn.net/jpg/01/43/11/20/360_F_143112044_gPXDDV55GTU8LQcX9GMbbP2Ss83ORMoy.jpg" alt="Stocene km"/>stočené kilometry</p>
                            </Col>
                        </Row>
                    }
                    {mileageValues != null &&
                        <Row>
                            <canvas id={r} width={"100%"}></canvas>
                        </Row>
                    }
                    <hr/>
                    <h2>Závady</h2>
                    {sortedData.map(function (event, i) {
                        return <>
                            <Row className={"p-2"}>
                                <>
                                <h5><><img className={"inspection-result-image"}
                                         src={GetImageByResult(event.note, event.issues.length)}></img> {formatDate(event.date)} - {event.mileage}km - {GetCheckType(event.checkType)}{event.checkDescription && " - " + event.checkDescription}
                                </></h5>
                                {event.issues.map(function (e, i) {
                                    return <p>{e}</p>
                                })}
                                </>
                            </Row>
                        </>
                    })}
                </Col>
            </Row>
        </>
    );
}

export default HistoryDetail;
