import React, {useState, useCallback, useEffect} from "react";
import logoHeader from './logo-header.png'
import logo from './logo.png'


const LogoNavbar: React.FC = () => {

    return (
        <div className={"w-100"}>
            <h1 className={"color-secondary text-center"}>Car Notifier</h1>
        </div>
    )
}
export default LogoNavbar;