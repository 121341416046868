import React, {useState, useCallback, useEffect} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {CarData} from "./VinReport";

function GetLogo(manufacturer: string) {
    return "/img/" + manufacturer.toLowerCase() + ".png"
}

function formatDate(dateString: string) {
    let date = new Date(dateString);

    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

function isOk(info: any) {
    return !(info === undefined || info === 0 || info === "" || info === "01.01.1" || info === "UNKNOWN")
}

const BasicInfoDetail: React.FC<{ carData: CarData }> = ({ carData }) => {

    let eq = carData

    return (
            <Row className={"report-body"}>
                <Col>
                    <Row className={"report-header"}>
                        <h2>Základní údaje</h2>
                        <Col xs={12} md={12}>
                            <Row>
                                {isOk(eq.manufacturer) &&
                                    <Col xs={12} md={3}><p><img width={40} src={GetLogo(eq.manufacturer)}
                                                                alt={eq.manufacturer}></img><strong>Výrobce:</strong> {eq.manufacturer}
                                    </p></Col>}
                                {isOk(eq.model) && <Col xs={12} md={3}><p><img width={40} alt="Model"
                                                                               src="https://i.pinimg.com/564x/c4/1c/db/c41cdb1eeea78c9203069c922bfc3a08.jpg"></img><strong>Model:</strong> {eq.model}
                                </p></Col>}
                                {isOk(eq.manufactured_year) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="Manufactured year"
                                                                src="https://cdn-icons-png.flaticon.com/512/4781/4781427.png"></img><strong>Rok
                                        výroby:</strong> {eq.manufactured_year}</p></Col>}
                                {isOk(eq.body_style) && <Col xs={12} md={3}><p><img width={40} alt="body style"
                                                                                    src="https://www.freeiconspng.com/uploads/-service-traffic-transport-travel-vehicle-icon--10.png"></img><strong>Karosérie:</strong> {eq.body_style}
                                </p></Col>}
                                {isOk(eq.manufactured_country) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="manufactured in"
                                                                src="https://cdn-icons-png.flaticon.com/512/2051/2051379.png"></img><strong>Země
                                        výroby:</strong> {eq.manufactured_country}</p></Col>}
                                {isOk(eq.manufactured_city) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="manufactured city"
                                                                src="https://cdn-icons-png.flaticon.com/512/2051/2051379.png"></img><strong>Město
                                        výroby:</strong> {eq.manufactured_city}</p></Col>}
                                {isOk(formatDate(eq.first_registration)) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="registration in CR"
                                                                src="https://cdn-icons-png.flaticon.com/512/1860/1860115.png"></img><strong>První
                                        registrace:</strong> {formatDate(eq.first_registration)}</p></Col>}
                                {isOk(formatDate(eq.registration_cr)) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="registration in CR"
                                                                src="https://images.emojiterra.com/google/noto-emoji/unicode-15/color/512px/1f1e8-1f1ff.png"></img><strong>Registrace
                                        v ČR:</strong> {formatDate(eq.registration_cr)}</p></Col>}
                                {isOk(eq.vin) && <Col xs={12} md={3}><p><img width={40} alt="Vin"
                                                                             src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt-6FNjevEqB7wUlX3Kik9lOncMnFYhveCCxmydM4l0MRHt3FhsmOLq5XzhJ0CeCq9FPU&usqp=CAU"></img><strong>VIN:</strong> {eq.vin}
                                </p></Col>}
                                {isOk(eq.seats) && <Col xs={12} md={3}><p><img width={40} alt="top speed"
                                                                               src="https://cdn-icons-png.flaticon.com/512/83/83628.png"></img><strong>Počet
                                    sedadel:</strong> {eq.seats}</p></Col>}
                                {isOk(eq.engine_cylinders) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="engine_cylinders"
                                                                src="https://cdn4.iconfinder.com/data/icons/automotive-maintenance/100/automotive-piston2-512.png"></img><strong>Válců
                                        motoru:</strong> {eq.engine_cylinders}</p></Col>}
                                {isOk(eq.engine_power) && <Col xs={12} md={3}><p><img width={40} alt="engine power"
                                                                                      src="https://cdn-icons-png.flaticon.com/512/1184/1184097.png"></img><strong>Výkon
                                    motoru:</strong> {eq.engine_power}kW</p></Col>}
                                {isOk(eq.engine_volume) && <Col xs={12} md={3}><p><img width={40} alt="engine volume"
                                                                                       src="https://icon-library.com/images/engine-icon-png/engine-icon-png-7.jpg"></img><strong>Objem
                                    motoru:</strong> {eq.engine_volume}cm3</p></Col>}
                                {isOk(eq.top_speed) && <Col xs={12} md={3}><p><img width={40} alt="top speed"
                                                                                   src="https://cdn.icon-icons.com/icons2/3251/PNG/512/top_speed_regular_icon_203118.png"></img><strong>Maximální
                                    rychlost:</strong> {eq.top_speed}km/h</p></Col>}
                                {isOk(eq.transmission) && <Col xs={12} md={3}><p><img width={40} alt="top speed"
                                                                                      src="https://cdn-icons-png.flaticon.com/512/2061/2061870.png"></img><strong>Převodovka:</strong> {eq.transmission}
                                </p></Col>}
                                {isOk(eq.color) && <Col xs={12} md={3}><p><img width={40} alt="engine power"
                                                                               src="https://cdn-icons-png.flaticon.com/512/4905/4905617.png"></img><strong>Barva:</strong> {eq.color}
                                </p></Col>}
                                {isOk(eq.engine_energy) && <Col xs={12} md={3}><p><img width={40} alt="engine energy"
                                                                                       src="https://cdn0.iconfinder.com/data/icons/cosmo-building/40/station_petrol-512.png"></img><strong>Palivo:</strong> {eq.engine_energy}
                                </p></Col>}
                                {isOk(eq.engine_type) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="engine type"
                                                                src="https://icon-library.com/images/engine-icon-png/engine-icon-png-7.jpg"></img><strong>Kód motoru:</strong> {eq.engine_type}l</p></Col>}
                                {isOk(eq.fuel_consumption_urban) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="engine volume"
                                                                src="https://cdn-icons-png.flaticon.com/512/4783/4783446.png"></img><strong>Spotřeba
                                        ve městě:</strong> {eq.fuel_consumption_urban}l</p></Col>}
                                {isOk(eq.fuel_consumption_highway) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="top speed"
                                                                src="https://cdn-icons-png.flaticon.com/512/64/64360.png"></img><strong>Spotřeba
                                        na dálnici:</strong> {eq.fuel_consumption_highway}l</p></Col>}
                                {isOk(eq.wheel_size_front) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="front wheels"
                                                                src="https://static.thenounproject.com/png/1808122-200.png"></img><strong>Přední kola:</strong> {eq.wheel_size_front}l</p></Col>}
                                {isOk(eq.wheel_size_rear) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="rear wheels"
                                                                src="https://static.thenounproject.com/png/1808127-200.png"></img><strong>Zadní kola:</strong> {eq.wheel_size_rear}l</p></Col>}
                                {isOk(eq.fuel_consumption_extra_urban) &&
                                    <Col xs={12} md={3}><p><img width={40} alt="engine power"
                                                                src="https://cdn-icons-png.flaticon.com/512/55/55261.png"></img><strong>Spotřeba
                                        mimo město:</strong> {eq.fuel_consumption_extra_urban}l</p></Col>}
                                {isOk(eq.wheel_drive) && <Col xs={12} md={3}><p><img width={40} alt="wheel_drive"
                                                                                     src="https://cdn-icons-png.flaticon.com/512/6151/6151294.png"></img><strong>Pohon
                                    kol:</strong> {eq.wheel_drive}</p></Col>}
                                {isOk(eq.car_length) && <Col xs={12} md={3}><p><img width={40} alt="engine power"
                                                                                    src="https://cdn4.iconfinder.com/data/icons/mobility-3/66/length_car_measure_vehicle-512.png"></img><strong>Délka
                                    auta:</strong> {eq.car_length}cm</p></Col>}
                                {isOk(eq.car_width) && <Col xs={12} md={3}><p><img width={40} alt="engine volume"
                                                                                   src="https://cdn-icons-png.flaticon.com/512/815/815509.png"></img><strong>Šířka
                                    auta:</strong> {eq.car_width}cm</p></Col>}
                                {isOk(eq.car_height) && <Col xs={12} md={3}><p><img width={40} alt="top speed"
                                                                                    src="https://static.thenounproject.com/png/2395974-200.png"></img><strong>Výška
                                    auta:</strong> {eq.car_height}cm</p></Col>}
                                {isOk(eq.car_weight) && <Col xs={12} md={3}><p><img width={40} alt="engine power"
                                                                                    src="https://i.pinimg.com/564x/6b/75/03/6b7503c381caede21532d0bd32f72451.jpg"></img><strong>Hmotnost
                                    auta:</strong> {eq.car_weight}kg</p></Col>}
                                {isOk(eq.car_max_weight) && <Col xs={12} md={3}><p><img width={40} alt="engine volume"
                                                                                        src="https://as2.ftcdn.net/v2/jpg/03/19/41/73/1000_F_319417385_zE3WlCW7womnPZfGs30442J6Xu7KX6zM.jpg"></img><strong>Maximální
                                    hmotnost:</strong> {eq.car_max_weight}kg</p></Col>}
                                {isOk(eq.emmision_level_fullfilment) && <Col xs={12} md={3}><p><img width={40} alt="emmision level fullfilment"
                                                                                        src="https://static.vecteezy.com/system/resources/previews/041/281/531/non_2x/carbon-emission-limits-energy-policy-glyph-icon-illustration-vector.jpg"></img><strong>Emisní norma:</strong> {eq.emmision_level_fullfilment}</p></Col>}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
    );
}

export default BasicInfoDetail;
