import React, { useState } from "react";
import { Button, Form, Col, Row } from 'react-bootstrap';
import axios, { AxiosError } from "axios";
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { API_HOST } from "../App";
import FullScreenLoading from "../loading/FullScreenLoading";


const discordClientId = "1214192063080570890";

interface LoginProps {
    setLoggedIn: (loggedIn: boolean) => void;
    token: string
}

const TokenForm: React.FC<LoginProps> = (props: LoginProps) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const nav = useNavigate();

    const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault(); // Prevent form submission

        function isValidEmail(email: string) {
            return /\S+@\S+\.\S+/.test(email);
        }

        if (!isValidEmail(username)) {
            NotificationManager.error("Invalid email");
            return;
        }

        let host = API_HOST;

        setIsLoading(true)

        if (password === '') {

            let generateRoute = "/login/token";
            axios.post(host + generateRoute, JSON.stringify({"email": username, "token": props.token}))
                .then(function (response) {
                    setShowPassword(true);
                    NotificationManager.success("Přihlaste se kódem z emailu");
                })
                .catch(function (error) {
                    if (error instanceof AxiosError) {
                        if (error.response && error.response.status === 403) {
                            NotificationManager.error("Neplatný kód");
                        }
                        if (error.response && error.response.status === 423) {
                            NotificationManager.error("Email již existuje");
                        }
                        else {
                            NotificationManager.error("An unexpected error occurred");
                        }
                    } else {
                        NotificationManager.error("An unexpected error occurred");
                    }
                })
                .finally(function () {
                    setIsLoading(false)
                });


        } else {
            let loginRoute = "/login";

            axios.post(host + loginRoute, JSON.stringify({ "email": username, "password": password }))
                .then(function (response: any) {
                    const token = response.data.token;
                    localStorage.clear();
                    localStorage.setItem("token", token);
                    props.setLoggedIn(true)
                    NotificationManager.success("Login successful");
                })
                .catch(function (error) {
                    NotificationManager.error("Invalid credentials");
                    console.error('Error:', error);
                })
                .finally(function () {
                    setIsLoading(false)
                });
        }
    }

    return (
        <Row xs={12}>
            {isLoading &&
                <FullScreenLoading></FullScreenLoading>
            }
            <Col>
                <Row className={"text-center pb-3"}>
                    <h2 className={"color-secondary"}>Získaváte předplatné zdarma!</h2>
                    <h3 className={"color-secondary text-racing"}>Zadejte váš email pro registraci.</h3>
                </Row>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Group controlId="username">
                                    <Form.Control
                                        type="email"
                                        name="username"
                                        placeholder={"E-mail"}
                                        onChange={handleUsername}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={"mt-3"}>
                            <Col>
                                <Form.Group controlId="username">
                                    <Form.Control
                                        type="token"
                                        name="token"
                                        value={props.token}
                                        placeholder={"Kód"}
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {showPassword &&
                            <Row className={"mt-3"}>
                                <Col>
                                    <Form.Group controlId="username">
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder={"Kód z emailu"}
                                            onChange={handlePassword}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        <Row className={"text-center mt-3"}>
                            <Col>
                                <Button className={"button-custom-primary"} type="submit">
                                    {!showPassword ? "Potvrdit promo kód" : "Přihlásit"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Col>
        </Row>
    );
}

export default TokenForm;
