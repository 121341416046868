import Modal from 'react-bootstrap/Modal';
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FullScreenLoading from "../loading/FullScreenLoading";

interface ModalProps {
    vin: string;
}

const LoadingModal: React.FC<ModalProps> = (props) => {
    return (
        <FullScreenLoading></FullScreenLoading>
    );
}

export default LoadingModal;